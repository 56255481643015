@import url('https://fonts.googleapis.com/css2?family=Gothic+A1:wght@400;700&family=Unica+One&display=swap');

/* font-family: 'Gothic A1', sans-serif;
font-family: 'Unica One', cursive; */

.App {
    text-align: center;
}

h1,
h2 {
    color: #31185E;
    text-decoration: none;
    font-size: 1rem;
    font-family: 'Unica One', cursive;
}

h1.page-name {
    color: white;
    width: 100%;
    font-size: 10vh;
    margin: 0;
}

.social-icons {
    /* padding: 0 5px; */
    display: flex;
    justify-content: center;
}

.social-icons ul {
    list-style: none;
    display: flex;
    margin: 0;
    padding: 0;
}

.social-icons ul li {
    margin: 0 5px;
}

.back-button {
    color: black;
    text-decoration: none;
}

.back-button h2 {
    margin: 0px;
    position: absolute;
    left: 20px;
}

.sub-page {
    position: absolute;
    top: 0;
    width: 100%;
    min-height: 100vh;
}

.animation {
    background-color: #FF78BD;
}

.digital {
    background-color: #b87ed9;
}

.sketches {
    background-color: #01ecee;
}

.about {
    background-color: #e2fc81;
}

.page-title {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    max-width: 1200px;
}

.page-title h1 {
    color: #31185E;
    text-transform: uppercase;
    font-size: 40px;
    margin-bottom: 0px;
}

.nav-images {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
    position: absolute;
    width: min(700px, 55%);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.reel{
    width: 96%;
    height: 360px;
    /* background-color: #000000; */
    margin: 5px;
}

.reel:hover{
    transform: scale(1.1);
}

.nav-images img {
    width: 100%;
}

.nav-images>.img-link {
    width: 30%;
    padding: 20px 20px 0 20px;
    margin: 5px;
    transition: width .2s ease-in-out;
    text-decoration: none;
}

.nav-images>.img-link:hover {
    transform: scale(1.1);
}

.container {
    position: relative;
}

.page {
    position: absolute;
    left: 0px;
    right: 0px;
}

.page-enter {
    position: absolute;
    bottom: 0;
    height: 0px;
}

.page-enter-active {
    height: 100%;
    transition: height 500ms;
    overflow: hidden;
    position: fixed;
}

.page-exit {
    position: absolute;
    bottom: 0;
    height: 100%;
}

.page-exit-active {
    height: 0px;
    transition: height 500ms;
    overflow: hidden;
    position: fixed;
}

/* FireFly */

    .firefly-container{
        width: 100%;
        overflow: hidden;
    }
  
  .firefly {
    position: fixed;
    left: 50%;
    top: 50%;
    width: 1.0vw;
    height: 1.0vw;
    margin: -0.2vw 0 0 9.8vw;
    animation: ease 200s alternate infinite;
    pointer-events: none;
z-index: 0; }
   
  .firefly::before, .firefly::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    transform-origin: -10vw; }
  .firefly::before {
    background: #03EBEE;
    opacity: 0.4;
    animation: drift ease alternate infinite; }
  .firefly::after {
    background: white;
    opacity: 0;
    box-shadow: 0 0 0vw 0vw yellow;
    animation: drift ease alternate infinite, flash ease infinite; }

.firefly:nth-child(1) {
  animation-name: move1; }
  .firefly:nth-child(1)::before {
    animation-duration: 12s; }
  .firefly:nth-child(1)::after {
    animation-duration: 12s, 8016ms;
    animation-delay: 0ms, 7808ms; }

@keyframes move1 {
  0% {
    transform: translateX(-32vw) translateY(-14vh) scale(0.36); }
  5% {
    transform: translateX(-27vw) translateY(36vh) scale(0.8); }
  10% {
    transform: translateX(-33vw) translateY(9vh) scale(0.58); }
  15% {
    transform: translateX(48vw) translateY(-30vh) scale(0.7); }
  20% {
    transform: translateX(-23vw) translateY(3vh) scale(0.61); }
  25% {
    transform: translateX(43vw) translateY(50vh) scale(0.39); }
  30% {
    transform: translateX(31vw) translateY(-46vh) scale(0.73); }
  35% {
    transform: translateX(24vw) translateY(-10vh) scale(0.93); }
  40% {
    transform: translateX(29vw) translateY(17vh) scale(0.42); }
  45% {
    transform: translateX(-23vw) translateY(10vh) scale(0.49); }
  50% {
    transform: translateX(-40vw) translateY(28vh) scale(0.73); }
  55% {
    transform: translateX(-24vw) translateY(-39vh) scale(0.32); }
  60% {
    transform: translateX(-24vw) translateY(39vh) scale(0.65); }
  65% {
    transform: translateX(33vw) translateY(46vh) scale(0.27); }
  70% {
    transform: translateX(24vw) translateY(24vh) scale(0.29); }
  75% {
    transform: translateX(43vw) translateY(-5vh) scale(0.99); }
  80% {
    transform: translateX(-15vw) translateY(-43vh) scale(0.45); }
  85% {
    transform: translateX(25vw) translateY(-22vh) scale(0.71); }
  90% {
    transform: translateX(23vw) translateY(-1vh) scale(0.93); }
  95% {
    transform: translateX(-29vw) translateY(-16vh) scale(0.94); }
  100% {
    transform: translateX(24vw) translateY(37vh) scale(0.8); } }

.firefly:nth-child(2) {
  animation-name: move2; }
  .firefly:nth-child(2)::before {
    animation-duration: 10s; }
  .firefly:nth-child(2)::after {
    animation-duration: 10s, 8239ms;
    animation-delay: 0ms, 6196ms; }

@keyframes move2 {
  0% {
    transform: translateX(6vw) translateY(-5vh) scale(0.59); }
  5% {
    transform: translateX(11vw) translateY(10vh) scale(0.93); }
  10% {
    transform: translateX(-5vw) translateY(48vh) scale(0.6); }
  15% {
    transform: translateX(21vw) translateY(-15vh) scale(0.67); }
  20% {
    transform: translateX(-29vw) translateY(43vh) scale(0.36); }
  25% {
    transform: translateX(35vw) translateY(15vh) scale(0.43); }
  30% {
    transform: translateX(20vw) translateY(39vh) scale(0.31); }
  35% {
    transform: translateX(-5vw) translateY(47vh) scale(0.57); }
  40% {
    transform: translateX(12vw) translateY(-29vh) scale(0.87); }
  45% {
    transform: translateX(32vw) translateY(43vh) scale(0.67); }
  50% {
    transform: translateX(-24vw) translateY(-10vh) scale(0.92); }
  55% {
    transform: translateX(41vw) translateY(-26vh) scale(0.79); }
  60% {
    transform: translateX(-3vw) translateY(37vh) scale(0.44); }
  65% {
    transform: translateX(46vw) translateY(-48vh) scale(0.4); }
  70% {
    transform: translateX(19vw) translateY(50vh) scale(0.6); }
  75% {
    transform: translateX(-45vw) translateY(-23vh) scale(0.53); }
  80% {
    transform: translateX(-32vw) translateY(32vh) scale(0.99); }
  85% {
    transform: translateX(-3vw) translateY(3vh) scale(0.55); }
  90% {
    transform: translateX(-46vw) translateY(1vh) scale(0.26); }
  95% {
    transform: translateX(-18vw) translateY(30vh) scale(0.78); }
  100% {
    transform: translateX(-26vw) translateY(31vh) scale(0.29); } }

.firefly:nth-child(3) {
  animation-name: move3; }
  .firefly:nth-child(3)::before {
    animation-duration: 12s; }
  .firefly:nth-child(3)::after {
    animation-duration: 12s, 7287ms;
    animation-delay: 0ms, 1418ms; }

@keyframes move3 {
  0% {
    transform: translateX(48vw) translateY(15vh) scale(0.63); }
  3.7037% {
    transform: translateX(28vw) translateY(-17vh) scale(0.87); }
  7.40741% {
    transform: translateX(13vw) translateY(18vh) scale(0.45); }
  11.11111% {
    transform: translateX(42vw) translateY(50vh) scale(0.97); }
  14.81481% {
    transform: translateX(-16vw) translateY(22vh) scale(0.67); }
  18.51852% {
    transform: translateX(-38vw) translateY(4vh) scale(0.87); }
  22.22222% {
    transform: translateX(31vw) translateY(-38vh) scale(0.92); }
  25.92593% {
    transform: translateX(-31vw) translateY(20vh) scale(0.49); }
  29.62963% {
    transform: translateX(38vw) translateY(-29vh) scale(0.32); }
  33.33333% {
    transform: translateX(-13vw) translateY(43vh) scale(0.68); }
  37.03704% {
    transform: translateX(-3vw) translateY(35vh) scale(0.89); }
  40.74074% {
    transform: translateX(-35vw) translateY(-5vh) scale(0.91); }
  44.44444% {
    transform: translateX(16vw) translateY(-24vh) scale(0.94); }
  48.14815% {
    transform: translateX(7vw) translateY(-16vh) scale(0.75); }
  51.85185% {
    transform: translateX(49vw) translateY(-39vh) scale(0.6); }
  55.55556% {
    transform: translateX(-21vw) translateY(-22vh) scale(0.7); }
  59.25926% {
    transform: translateX(-15vw) translateY(8vh) scale(0.81); }
  62.96296% {
    transform: translateX(40vw) translateY(12vh) scale(0.74); }
  66.66667% {
    transform: translateX(21vw) translateY(12vh) scale(0.93); }
  70.37037% {
    transform: translateX(26vw) translateY(45vh) scale(0.4); }
  74.07407% {
    transform: translateX(10vw) translateY(-32vh) scale(0.6); }
  77.77778% {
    transform: translateX(24vw) translateY(-25vh) scale(0.48); }
  81.48148% {
    transform: translateX(24vw) translateY(32vh) scale(0.77); }
  85.18519% {
    transform: translateX(-5vw) translateY(-1vh) scale(0.95); }
  88.88889% {
    transform: translateX(-39vw) translateY(12vh) scale(0.73); }
  92.59259% {
    transform: translateX(10vw) translateY(-5vh) scale(0.4); }
  96.2963% {
    transform: translateX(33vw) translateY(-17vh) scale(0.33); }
  100% {
    transform: translateX(30vw) translateY(32vh) scale(0.91); } }

.firefly:nth-child(4) {
  animation-name: move4; }
  .firefly:nth-child(4)::before {
    animation-duration: 13s; }
  .firefly:nth-child(4)::after {
    animation-duration: 13s, 10192ms;
    animation-delay: 0ms, 5936ms; }

@keyframes move4 {
  0% {
    transform: translateX(-1vw) translateY(17vh) scale(0.42); }
  4% {
    transform: translateX(38vw) translateY(4vh) scale(0.81); }
  8% {
    transform: translateX(10vw) translateY(-28vh) scale(0.41); }
  12% {
    transform: translateX(-9vw) translateY(-42vh) scale(0.52); }
  16% {
    transform: translateX(-28vw) translateY(30vh) scale(0.4); }
  20% {
    transform: translateX(35vw) translateY(5vh) scale(0.89); }
  24% {
    transform: translateX(-21vw) translateY(-16vh) scale(0.87); }
  28% {
    transform: translateX(-11vw) translateY(26vh) scale(0.95); }
  32% {
    transform: translateX(-31vw) translateY(27vh) scale(0.82); }
  36% {
    transform: translateX(18vw) translateY(-44vh) scale(0.86); }
  40% {
    transform: translateX(-34vw) translateY(44vh) scale(0.47); }
  44% {
    transform: translateX(-42vw) translateY(-30vh) scale(0.43); }
  48% {
    transform: translateX(-20vw) translateY(-31vh) scale(0.93); }
  52% {
    transform: translateX(-49vw) translateY(-33vh) scale(0.82); }
  56% {
    transform: translateX(-48vw) translateY(12vh) scale(0.99); }
  60% {
    transform: translateX(32vw) translateY(-30vh) scale(0.44); }
  64% {
    transform: translateX(15vw) translateY(-34vh) scale(0.7); }
  68% {
    transform: translateX(27vw) translateY(-25vh) scale(0.41); }
  72% {
    transform: translateX(-45vw) translateY(-46vh) scale(0.48); }
  76% {
    transform: translateX(45vw) translateY(-14vh) scale(0.99); }
  80% {
    transform: translateX(-45vw) translateY(11vh) scale(0.48); }
  84% {
    transform: translateX(35vw) translateY(38vh) scale(0.63); }
  88% {
    transform: translateX(16vw) translateY(-20vh) scale(0.78); }
  92% {
    transform: translateX(5vw) translateY(-3vh) scale(0.34); }
  96% {
    transform: translateX(28vw) translateY(-2vh) scale(0.77); }
  100% {
    transform: translateX(43vw) translateY(4vh) scale(0.88); } }

.firefly:nth-child(5) {
  animation-name: move5; }
  .firefly:nth-child(5)::before {
    animation-duration: 15s; }
  .firefly:nth-child(5)::after {
    animation-duration: 15s, 8629ms;
    animation-delay: 0ms, 6901ms; }

@keyframes move5 {
  0% {
    transform: translateX(-22vw) translateY(-10vh) scale(0.72); }
  5.26316% {
    transform: translateX(43vw) translateY(-18vh) scale(0.67); }
  10.52632% {
    transform: translateX(38vw) translateY(-20vh) scale(0.35); }
  15.78947% {
    transform: translateX(-31vw) translateY(1vh) scale(0.56); }
  21.05263% {
    transform: translateX(27vw) translateY(-4vh) scale(0.26); }
  26.31579% {
    transform: translateX(0vw) translateY(-22vh) scale(0.47); }
  31.57895% {
    transform: translateX(-35vw) translateY(24vh) scale(0.78); }
  36.84211% {
    transform: translateX(-46vw) translateY(40vh) scale(0.36); }
  42.10526% {
    transform: translateX(-37vw) translateY(5vh) scale(0.26); }
  47.36842% {
    transform: translateX(-37vw) translateY(23vh) scale(0.64); }
  52.63158% {
    transform: translateX(-25vw) translateY(-37vh) scale(0.48); }
  57.89474% {
    transform: translateX(-9vw) translateY(-36vh) scale(0.67); }
  63.15789% {
    transform: translateX(17vw) translateY(-18vh) scale(0.89); }
  68.42105% {
    transform: translateX(-6vw) translateY(-49vh) scale(0.74); }
  73.68421% {
    transform: translateX(50vw) translateY(-32vh) scale(0.76); }
  78.94737% {
    transform: translateX(28vw) translateY(12vh) scale(0.3); }
  84.21053% {
    transform: translateX(-21vw) translateY(11vh) scale(0.82); }
  89.47368% {
    transform: translateX(-38vw) translateY(-39vh) scale(0.73); }
  94.73684% {
    transform: translateX(48vw) translateY(15vh) scale(0.43); }
  100% {
    transform: translateX(33vw) translateY(33vh) scale(0.76); } }

.firefly:nth-child(6) {
  animation-name: move6; }
  .firefly:nth-child(6)::before {
    animation-duration: 15s; }
  .firefly:nth-child(6)::after {
    animation-duration: 15s, 8989ms;
    animation-delay: 0ms, 6096ms; }

@keyframes move6 {
  0% {
    transform: translateX(8vw) translateY(49vh) scale(0.78); }
  4.34783% {
    transform: translateX(6vw) translateY(-47vh) scale(0.85); }
  8.69565% {
    transform: translateX(-47vw) translateY(-29vh) scale(0.4); }
  13.04348% {
    transform: translateX(-42vw) translateY(-7vh) scale(0.43); }
  17.3913% {
    transform: translateX(12vw) translateY(-7vh) scale(0.43); }
  21.73913% {
    transform: translateX(-30vw) translateY(32vh) scale(0.58); }
  26.08696% {
    transform: translateX(-3vw) translateY(-40vh) scale(0.46); }
  30.43478% {
    transform: translateX(-5vw) translateY(24vh) scale(0.37); }
  34.78261% {
    transform: translateX(15vw) translateY(33vh) scale(0.77); }
  39.13043% {
    transform: translateX(9vw) translateY(-34vh) scale(0.74); }
  43.47826% {
    transform: translateX(-29vw) translateY(-31vh) scale(0.59); }
  47.82609% {
    transform: translateX(10vw) translateY(-19vh) scale(0.37); }
  52.17391% {
    transform: translateX(-3vw) translateY(-32vh) scale(0.45); }
  56.52174% {
    transform: translateX(-21vw) translateY(26vh) scale(0.9); }
  60.86957% {
    transform: translateX(37vw) translateY(15vh) scale(0.61); }
  65.21739% {
    transform: translateX(28vw) translateY(-6vh) scale(0.46); }
  69.56522% {
    transform: translateX(30vw) translateY(-45vh) scale(0.45); }
  73.91304% {
    transform: translateX(31vw) translateY(-32vh) scale(0.71); }
  78.26087% {
    transform: translateX(-39vw) translateY(2vh) scale(0.43); }
  82.6087% {
    transform: translateX(-25vw) translateY(45vh) scale(0.6); }
  86.95652% {
    transform: translateX(20vw) translateY(-37vh) scale(0.88); }
  91.30435% {
    transform: translateX(50vw) translateY(-18vh) scale(0.72); }
  95.65217% {
    transform: translateX(-7vw) translateY(-5vh) scale(0.96); }
  100% {
    transform: translateX(-36vw) translateY(12vh) scale(0.41); } }

.firefly:nth-child(7) {
  animation-name: move7; }
  .firefly:nth-child(7)::before {
    animation-duration: 15s; }
  .firefly:nth-child(7)::after {
    animation-duration: 15s, 8220ms;
    animation-delay: 0ms, 3704ms; }

@keyframes move7 {
  0% {
    transform: translateX(-43vw) translateY(-2vh) scale(0.93); }
  4.34783% {
    transform: translateX(-37vw) translateY(-32vh) scale(0.3); }
  8.69565% {
    transform: translateX(4vw) translateY(-12vh) scale(0.74); }
  13.04348% {
    transform: translateX(-16vw) translateY(-47vh) scale(0.99); }
  17.3913% {
    transform: translateX(-37vw) translateY(37vh) scale(0.36); }
  21.73913% {
    transform: translateX(-29vw) translateY(-17vh) scale(0.85); }
  26.08696% {
    transform: translateX(-36vw) translateY(32vh) scale(0.4); }
  30.43478% {
    transform: translateX(19vw) translateY(-13vh) scale(0.62); }
  34.78261% {
    transform: translateX(10vw) translateY(-19vh) scale(0.72); }
  39.13043% {
    transform: translateX(14vw) translateY(32vh) scale(0.35); }
  43.47826% {
    transform: translateX(-34vw) translateY(-34vh) scale(0.39); }
  47.82609% {
    transform: translateX(-21vw) translateY(-24vh) scale(0.73); }
  52.17391% {
    transform: translateX(31vw) translateY(-26vh) scale(0.51); }
  56.52174% {
    transform: translateX(-25vw) translateY(-20vh) scale(0.4); }
  60.86957% {
    transform: translateX(14vw) translateY(45vh) scale(0.35); }
  65.21739% {
    transform: translateX(-31vw) translateY(-9vh) scale(0.79); }
  69.56522% {
    transform: translateX(2vw) translateY(-22vh) scale(0.73); }
  73.91304% {
    transform: translateX(3vw) translateY(-39vh) scale(0.36); }
  78.26087% {
    transform: translateX(29vw) translateY(-19vh) scale(0.93); }
  82.6087% {
    transform: translateX(10vw) translateY(-23vh) scale(0.27); }
  86.95652% {
    transform: translateX(46vw) translateY(42vh) scale(0.77); }
  91.30435% {
    transform: translateX(20vw) translateY(49vh) scale(0.37); }
  95.65217% {
    transform: translateX(-49vw) translateY(25vh) scale(0.66); }
  100% {
    transform: translateX(-34vw) translateY(3vh) scale(0.3); } }

.firefly:nth-child(8) {
  animation-name: move8; }
  .firefly:nth-child(8)::before {
    animation-duration: 9s; }
  .firefly:nth-child(8)::after {
    animation-duration: 9s, 5054ms;
    animation-delay: 0ms, 5903ms; }

@keyframes move8 {
  0% {
    transform: translateX(48vw) translateY(29vh) scale(0.42); }
  4% {
    transform: translateX(46vw) translateY(46vh) scale(0.49); }
  8% {
    transform: translateX(10vw) translateY(45vh) scale(0.46); }
  12% {
    transform: translateX(-12vw) translateY(-24vh) scale(0.63); }
  16% {
    transform: translateX(45vw) translateY(-47vh) scale(0.77); }
  20% {
    transform: translateX(-10vw) translateY(43vh) scale(0.99); }
  24% {
    transform: translateX(42vw) translateY(12vh) scale(0.48); }
  28% {
    transform: translateX(-36vw) translateY(-33vh) scale(0.37); }
  32% {
    transform: translateX(-45vw) translateY(37vh) scale(0.88); }
  36% {
    transform: translateX(12vw) translateY(-31vh) scale(0.96); }
  40% {
    transform: translateX(8vw) translateY(-40vh) scale(0.78); }
  44% {
    transform: translateX(-16vw) translateY(41vh) scale(0.84); }
  48% {
    transform: translateX(-4vw) translateY(-17vh) scale(0.28); }
  52% {
    transform: translateX(-25vw) translateY(42vh) scale(0.72); }
  56% {
    transform: translateX(9vw) translateY(34vh) scale(0.54); }
  60% {
    transform: translateX(4vw) translateY(-18vh) scale(0.81); }
  64% {
    transform: translateX(-48vw) translateY(-18vh) scale(0.68); }
  68% {
    transform: translateX(11vw) translateY(44vh) scale(0.63); }
  72% {
    transform: translateX(28vw) translateY(2vh) scale(0.75); }
  76% {
    transform: translateX(-26vw) translateY(22vh) scale(0.8); }
  80% {
    transform: translateX(30vw) translateY(15vh) scale(0.73); }
  84% {
    transform: translateX(17vw) translateY(-5vh) scale(0.32); }
  88% {
    transform: translateX(28vw) translateY(6vh) scale(0.46); }
  92% {
    transform: translateX(17vw) translateY(-11vh) scale(0.73); }
  96% {
    transform: translateX(40vw) translateY(42vh) scale(0.68); }
  100% {
    transform: translateX(41vw) translateY(-35vh) scale(0.93); } }

.firefly:nth-child(9) {
  animation-name: move9; }
  .firefly:nth-child(9)::before {
    animation-duration: 15s; }
  .firefly:nth-child(9)::after {
    animation-duration: 15s, 8058ms;
    animation-delay: 0ms, 927ms; }

@keyframes move9 {
  0% {
    transform: translateX(-12vw) translateY(-12vh) scale(0.92); }
  3.57143% {
    transform: translateX(12vw) translateY(28vh) scale(0.98); }
  7.14286% {
    transform: translateX(-40vw) translateY(15vh) scale(0.54); }
  10.71429% {
    transform: translateX(26vw) translateY(-19vh) scale(0.33); }
  14.28571% {
    transform: translateX(33vw) translateY(14vh) scale(0.74); }
  17.85714% {
    transform: translateX(17vw) translateY(-2vh) scale(0.63); }
  21.42857% {
    transform: translateX(39vw) translateY(7vh) scale(0.59); }
  25% {
    transform: translateX(36vw) translateY(9vh) scale(0.72); }
  28.57143% {
    transform: translateX(48vw) translateY(-26vh) scale(0.43); }
  32.14286% {
    transform: translateX(-44vw) translateY(40vh) scale(0.39); }
  35.71429% {
    transform: translateX(27vw) translateY(16vh) scale(0.56); }
  39.28571% {
    transform: translateX(-33vw) translateY(-39vh) scale(0.38); }
  42.85714% {
    transform: translateX(-30vw) translateY(8vh) scale(0.74); }
  46.42857% {
    transform: translateX(-33vw) translateY(16vh) scale(0.42); }
  50% {
    transform: translateX(37vw) translateY(9vh) scale(0.88); }
  53.57143% {
    transform: translateX(-33vw) translateY(31vh) scale(0.68); }
  57.14286% {
    transform: translateX(31vw) translateY(-35vh) scale(0.43); }
  60.71429% {
    transform: translateX(5vw) translateY(-18vh) scale(0.32); }
  64.28571% {
    transform: translateX(31vw) translateY(30vh) scale(0.99); }
  67.85714% {
    transform: translateX(0vw) translateY(-44vh) scale(0.7); }
  71.42857% {
    transform: translateX(-31vw) translateY(27vh) scale(0.26); }
  75% {
    transform: translateX(-46vw) translateY(46vh) scale(0.59); }
  78.57143% {
    transform: translateX(-12vw) translateY(-13vh) scale(0.35); }
  82.14286% {
    transform: translateX(7vw) translateY(2vh) scale(0.54); }
  85.71429% {
    transform: translateX(34vw) translateY(-13vh) scale(0.86); }
  89.28571% {
    transform: translateX(-36vw) translateY(-16vh) scale(0.87); }
  92.85714% {
    transform: translateX(28vw) translateY(0vh) scale(0.83); }
  96.42857% {
    transform: translateX(-3vw) translateY(-28vh) scale(0.98); }
  100% {
    transform: translateX(8vw) translateY(50vh) scale(0.81); } }

.firefly:nth-child(10) {
  animation-name: move10; }
  .firefly:nth-child(10)::before {
    animation-duration: 9s; }
  .firefly:nth-child(10)::after {
    animation-duration: 9s, 5190ms;
    animation-delay: 0ms, 1838ms; }

@keyframes move10 {
  0% {
    transform: translateX(-5vw) translateY(-28vh) scale(0.97); }
  5% {
    transform: translateX(33vw) translateY(-40vh) scale(0.63); }
  10% {
    transform: translateX(0vw) translateY(14vh) scale(0.4); }
  15% {
    transform: translateX(21vw) translateY(15vh) scale(0.26); }
  20% {
    transform: translateX(-33vw) translateY(-4vh) scale(0.89); }
  25% {
    transform: translateX(7vw) translateY(44vh) scale(0.55); }
  30% {
    transform: translateX(8vw) translateY(-26vh) scale(0.27); }
  35% {
    transform: translateX(-16vw) translateY(34vh) scale(0.69); }
  40% {
    transform: translateX(-13vw) translateY(0vh) scale(0.63); }
  45% {
    transform: translateX(-6vw) translateY(-30vh) scale(0.58); }
  50% {
    transform: translateX(23vw) translateY(-22vh) scale(0.55); }
  55% {
    transform: translateX(16vw) translateY(16vh) scale(0.5); }
  60% {
    transform: translateX(-26vw) translateY(-14vh) scale(1); }
  65% {
    transform: translateX(6vw) translateY(-31vh) scale(0.87); }
  70% {
    transform: translateX(22vw) translateY(14vh) scale(0.35); }
  75% {
    transform: translateX(-32vw) translateY(-4vh) scale(0.86); }
  80% {
    transform: translateX(4vw) translateY(-8vh) scale(0.33); }
  85% {
    transform: translateX(4vw) translateY(33vh) scale(0.82); }
  90% {
    transform: translateX(-42vw) translateY(37vh) scale(0.88); }
  95% {
    transform: translateX(-39vw) translateY(22vh) scale(0.83); }
  100% {
    transform: translateX(-47vw) translateY(-10vh) scale(0.49); } }

.firefly:nth-child(11) {
  animation-name: move11; }
  .firefly:nth-child(11)::before {
    animation-duration: 9s; }
  .firefly:nth-child(11)::after {
    animation-duration: 9s, 7872ms;
    animation-delay: 0ms, 4755ms; }

@keyframes move11 {
  0% {
    transform: translateX(-9vw) translateY(-11vh) scale(0.97); }
  4.7619% {
    transform: translateX(39vw) translateY(-39vh) scale(0.64); }
  9.52381% {
    transform: translateX(7vw) translateY(11vh) scale(0.92); }
  14.28571% {
    transform: translateX(25vw) translateY(-5vh) scale(0.53); }
  19.04762% {
    transform: translateX(22vw) translateY(20vh) scale(0.45); }
  23.80952% {
    transform: translateX(21vw) translateY(46vh) scale(0.78); }
  28.57143% {
    transform: translateX(-33vw) translateY(16vh) scale(0.38); }
  33.33333% {
    transform: translateX(42vw) translateY(-43vh) scale(0.43); }
  38.09524% {
    transform: translateX(7vw) translateY(16vh) scale(0.54); }
  42.85714% {
    transform: translateX(-33vw) translateY(-28vh) scale(0.37); }
  47.61905% {
    transform: translateX(16vw) translateY(44vh) scale(0.8); }
  52.38095% {
    transform: translateX(-25vw) translateY(9vh) scale(0.68); }
  57.14286% {
    transform: translateX(9vw) translateY(28vh) scale(0.84); }
  61.90476% {
    transform: translateX(48vw) translateY(-48vh) scale(0.93); }
  66.66667% {
    transform: translateX(13vw) translateY(-12vh) scale(0.4); }
  71.42857% {
    transform: translateX(33vw) translateY(39vh) scale(0.63); }
  76.19048% {
    transform: translateX(-24vw) translateY(6vh) scale(0.79); }
  80.95238% {
    transform: translateX(27vw) translateY(-43vh) scale(0.49); }
  85.71429% {
    transform: translateX(-9vw) translateY(-9vh) scale(0.91); }
  90.47619% {
    transform: translateX(-29vw) translateY(27vh) scale(0.53); }
  95.2381% {
    transform: translateX(11vw) translateY(11vh) scale(0.39); }
  100% {
    transform: translateX(-13vw) translateY(0vh) scale(0.9); } }

.firefly:nth-child(12) {
  animation-name: move12; }
  .firefly:nth-child(12)::before {
    animation-duration: 14s; }
  .firefly:nth-child(12)::after {
    animation-duration: 14s, 10950ms;
    animation-delay: 0ms, 2856ms; }

@keyframes move12 {
  0% {
    transform: translateX(-49vw) translateY(-46vh) scale(0.76); }
  4.7619% {
    transform: translateX(37vw) translateY(-41vh) scale(0.32); }
  9.52381% {
    transform: translateX(-21vw) translateY(4vh) scale(0.83); }
  14.28571% {
    transform: translateX(-11vw) translateY(-7vh) scale(0.68); }
  19.04762% {
    transform: translateX(-29vw) translateY(22vh) scale(0.42); }
  23.80952% {
    transform: translateX(-36vw) translateY(12vh) scale(0.8); }
  28.57143% {
    transform: translateX(36vw) translateY(33vh) scale(0.95); }
  33.33333% {
    transform: translateX(-27vw) translateY(39vh) scale(0.69); }
  38.09524% {
    transform: translateX(-35vw) translateY(37vh) scale(0.95); }
  42.85714% {
    transform: translateX(-2vw) translateY(-12vh) scale(0.8); }
  47.61905% {
    transform: translateX(-26vw) translateY(20vh) scale(0.35); }
  52.38095% {
    transform: translateX(-42vw) translateY(0vh) scale(0.55); }
  57.14286% {
    transform: translateX(-34vw) translateY(-36vh) scale(0.83); }
  61.90476% {
    transform: translateX(-6vw) translateY(-21vh) scale(0.88); }
  66.66667% {
    transform: translateX(44vw) translateY(-40vh) scale(0.63); }
  71.42857% {
    transform: translateX(38vw) translateY(22vh) scale(0.54); }
  76.19048% {
    transform: translateX(25vw) translateY(19vh) scale(0.87); }
  80.95238% {
    transform: translateX(46vw) translateY(4vh) scale(0.49); }
  85.71429% {
    transform: translateX(-20vw) translateY(46vh) scale(0.93); }
  90.47619% {
    transform: translateX(-6vw) translateY(-30vh) scale(0.45); }
  95.2381% {
    transform: translateX(-41vw) translateY(12vh) scale(0.56); }
  100% {
    transform: translateX(-38vw) translateY(6vh) scale(0.64); } }

.firefly:nth-child(13) {
  animation-name: move13; }
  .firefly:nth-child(13)::before {
    animation-duration: 15s; }
  .firefly:nth-child(13)::after {
    animation-duration: 15s, 10732ms;
    animation-delay: 0ms, 2245ms; }

@keyframes move13 {
  0% {
    transform: translateX(31vw) translateY(38vh) scale(0.92); }
  5.26316% {
    transform: translateX(-5vw) translateY(-29vh) scale(0.26); }
  10.52632% {
    transform: translateX(42vw) translateY(-4vh) scale(0.5); }
  15.78947% {
    transform: translateX(44vw) translateY(9vh) scale(0.67); }
  21.05263% {
    transform: translateX(-35vw) translateY(43vh) scale(0.49); }
  26.31579% {
    transform: translateX(39vw) translateY(32vh) scale(0.97); }
  31.57895% {
    transform: translateX(5vw) translateY(-28vh) scale(0.39); }
  36.84211% {
    transform: translateX(-35vw) translateY(-12vh) scale(0.57); }
  42.10526% {
    transform: translateX(6vw) translateY(19vh) scale(0.5); }
  47.36842% {
    transform: translateX(40vw) translateY(47vh) scale(0.73); }
  52.63158% {
    transform: translateX(28vw) translateY(5vh) scale(0.77); }
  57.89474% {
    transform: translateX(50vw) translateY(2vh) scale(0.42); }
  63.15789% {
    transform: translateX(40vw) translateY(-46vh) scale(0.79); }
  68.42105% {
    transform: translateX(-10vw) translateY(-3vh) scale(0.41); }
  73.68421% {
    transform: translateX(-19vw) translateY(11vh) scale(0.8); }
  78.94737% {
    transform: translateX(-14vw) translateY(-39vh) scale(1); }
  84.21053% {
    transform: translateX(-5vw) translateY(4vh) scale(0.38); }
  89.47368% {
    transform: translateX(-25vw) translateY(17vh) scale(0.73); }
  94.73684% {
    transform: translateX(14vw) translateY(-45vh) scale(0.36); }
  100% {
    transform: translateX(-18vw) translateY(16vh) scale(0.4); } }

.firefly:nth-child(14) {
  animation-name: move14; }
  .firefly:nth-child(14)::before {
    animation-duration: 9s; }
  .firefly:nth-child(14)::after {
    animation-duration: 9s, 7337ms;
    animation-delay: 0ms, 6525ms; }

@keyframes move14 {
  0% {
    transform: translateX(-37vw) translateY(18vh) scale(0.93); }
  5.55556% {
    transform: translateX(28vw) translateY(1vh) scale(0.45); }
  11.11111% {
    transform: translateX(27vw) translateY(1vh) scale(0.86); }
  16.66667% {
    transform: translateX(30vw) translateY(-6vh) scale(0.75); }
  22.22222% {
    transform: translateX(-39vw) translateY(-34vh) scale(0.38); }
  27.77778% {
    transform: translateX(40vw) translateY(44vh) scale(0.87); }
  33.33333% {
    transform: translateX(-2vw) translateY(-5vh) scale(0.6); }
  38.88889% {
    transform: translateX(46vw) translateY(-29vh) scale(0.84); }
  44.44444% {
    transform: translateX(-27vw) translateY(-29vh) scale(0.29); }
  50% {
    transform: translateX(10vw) translateY(-20vh) scale(0.61); }
  55.55556% {
    transform: translateX(-11vw) translateY(-24vh) scale(0.27); }
  61.11111% {
    transform: translateX(-26vw) translateY(-29vh) scale(0.41); }
  66.66667% {
    transform: translateX(-22vw) translateY(38vh) scale(0.82); }
  72.22222% {
    transform: translateX(30vw) translateY(-16vh) scale(0.53); }
  77.77778% {
    transform: translateX(38vw) translateY(-15vh) scale(0.68); }
  83.33333% {
    transform: translateX(-46vw) translateY(-9vh) scale(0.82); }
  88.88889% {
    transform: translateX(4vw) translateY(-26vh) scale(0.96); }
  94.44444% {
    transform: translateX(-24vw) translateY(-41vh) scale(0.44); }
  100% {
    transform: translateX(-13vw) translateY(24vh) scale(0.39); } }

.firefly:nth-child(15) {
  animation-name: move15; }
  .firefly:nth-child(15)::before {
    animation-duration: 10s; }
  .firefly:nth-child(15)::after {
    animation-duration: 10s, 5624ms;
    animation-delay: 0ms, 7757ms; }

@keyframes move15 {
  0% {
    transform: translateX(18vw) translateY(-46vh) scale(0.31); }
  3.84615% {
    transform: translateX(-4vw) translateY(45vh) scale(0.59); }
  7.69231% {
    transform: translateX(-20vw) translateY(-28vh) scale(0.34); }
  11.53846% {
    transform: translateX(36vw) translateY(-5vh) scale(0.44); }
  15.38462% {
    transform: translateX(39vw) translateY(27vh) scale(0.44); }
  19.23077% {
    transform: translateX(9vw) translateY(47vh) scale(0.34); }
  23.07692% {
    transform: translateX(-41vw) translateY(23vh) scale(0.74); }
  26.92308% {
    transform: translateX(5vw) translateY(-28vh) scale(0.45); }
  30.76923% {
    transform: translateX(-40vw) translateY(-21vh) scale(0.81); }
  34.61538% {
    transform: translateX(-40vw) translateY(-48vh) scale(0.73); }
  38.46154% {
    transform: translateX(30vw) translateY(-5vh) scale(0.98); }
  42.30769% {
    transform: translateX(-24vw) translateY(46vh) scale(0.42); }
  46.15385% {
    transform: translateX(47vw) translateY(36vh) scale(0.78); }
  50% {
    transform: translateX(13vw) translateY(-41vh) scale(0.43); }
  53.84615% {
    transform: translateX(33vw) translateY(-15vh) scale(0.78); }
  57.69231% {
    transform: translateX(-47vw) translateY(17vh) scale(0.34); }
  61.53846% {
    transform: translateX(47vw) translateY(14vh) scale(0.71); }
  65.38462% {
    transform: translateX(-48vw) translateY(42vh) scale(0.99); }
  69.23077% {
    transform: translateX(31vw) translateY(21vh) scale(0.5); }
  73.07692% {
    transform: translateX(-8vw) translateY(-46vh) scale(0.41); }
  76.92308% {
    transform: translateX(-35vw) translateY(-2vh) scale(0.31); }
  80.76923% {
    transform: translateX(-18vw) translateY(48vh) scale(0.49); }
  84.61538% {
    transform: translateX(-13vw) translateY(-44vh) scale(0.96); }
  88.46154% {
    transform: translateX(-31vw) translateY(-18vh) scale(0.93); }
  92.30769% {
    transform: translateX(-17vw) translateY(-27vh) scale(0.72); }
  96.15385% {
    transform: translateX(-43vw) translateY(-37vh) scale(0.63); }
  100% {
    transform: translateX(3vw) translateY(-14vh) scale(0.84); } }

.firefly:nth-child(16) {
  animation-name: move16; }
  .firefly:nth-child(16)::before {
    animation-duration: 9s; }
  .firefly:nth-child(16)::after {
    animation-duration: 9s, 6914ms;
    animation-delay: 0ms, 3578ms; }

@keyframes move16 {
  0% {
    transform: translateX(15vw) translateY(-17vh) scale(0.35); }
  5.88235% {
    transform: translateX(-13vw) translateY(-35vh) scale(0.32); }
  11.76471% {
    transform: translateX(21vw) translateY(-42vh) scale(0.98); }
  17.64706% {
    transform: translateX(-38vw) translateY(30vh) scale(0.71); }
  23.52941% {
    transform: translateX(45vw) translateY(25vh) scale(0.54); }
  29.41176% {
    transform: translateX(-3vw) translateY(-24vh) scale(0.47); }
  35.29412% {
    transform: translateX(-3vw) translateY(-28vh) scale(0.7); }
  41.17647% {
    transform: translateX(39vw) translateY(-35vh) scale(0.6); }
  47.05882% {
    transform: translateX(-35vw) translateY(19vh) scale(0.87); }
  52.94118% {
    transform: translateX(-26vw) translateY(-5vh) scale(0.86); }
  58.82353% {
    transform: translateX(-21vw) translateY(-47vh) scale(0.95); }
  64.70588% {
    transform: translateX(29vw) translateY(-21vh) scale(0.84); }
  70.58824% {
    transform: translateX(-24vw) translateY(-41vh) scale(0.64); }
  76.47059% {
    transform: translateX(-7vw) translateY(23vh) scale(0.88); }
  82.35294% {
    transform: translateX(5vw) translateY(37vh) scale(0.45); }
  88.23529% {
    transform: translateX(-10vw) translateY(-34vh) scale(0.96); }
  94.11765% {
    transform: translateX(-42vw) translateY(-8vh) scale(0.72); }
  100% {
    transform: translateX(7vw) translateY(-5vh) scale(0.4); } }

.firefly:nth-child(17) {
  animation-name: move17; }
  .firefly:nth-child(17)::before {
    animation-duration: 18s; }
  .firefly:nth-child(17)::after {
    animation-duration: 18s, 7010ms;
    animation-delay: 0ms, 5873ms; }

@keyframes move17 {
  0% {
    transform: translateX(-22vw) translateY(18vh) scale(0.3); }
  4% {
    transform: translateX(-46vw) translateY(8vh) scale(0.85); }
  8% {
    transform: translateX(33vw) translateY(-23vh) scale(0.32); }
  12% {
    transform: translateX(40vw) translateY(-5vh) scale(0.46); }
  16% {
    transform: translateX(30vw) translateY(32vh) scale(0.98); }
  20% {
    transform: translateX(-30vw) translateY(47vh) scale(0.78); }
  24% {
    transform: translateX(25vw) translateY(26vh) scale(0.85); }
  28% {
    transform: translateX(17vw) translateY(6vh) scale(0.98); }
  32% {
    transform: translateX(-40vw) translateY(14vh) scale(0.54); }
  36% {
    transform: translateX(16vw) translateY(-26vh) scale(0.73); }
  40% {
    transform: translateX(38vw) translateY(-39vh) scale(0.91); }
  44% {
    transform: translateX(-21vw) translateY(4vh) scale(0.97); }
  48% {
    transform: translateX(-24vw) translateY(30vh) scale(0.98); }
  52% {
    transform: translateX(39vw) translateY(-37vh) scale(0.73); }
  56% {
    transform: translateX(-12vw) translateY(14vh) scale(0.59); }
  60% {
    transform: translateX(-37vw) translateY(-24vh) scale(0.42); }
  64% {
    transform: translateX(-18vw) translateY(-37vh) scale(0.33); }
  68% {
    transform: translateX(28vw) translateY(-31vh) scale(0.86); }
  72% {
    transform: translateX(-1vw) translateY(-26vh) scale(0.83); }
  76% {
    transform: translateX(27vw) translateY(29vh) scale(0.54); }
  80% {
    transform: translateX(46vw) translateY(-26vh) scale(0.89); }
  84% {
    transform: translateX(10vw) translateY(-22vh) scale(0.47); }
  88% {
    transform: translateX(-22vw) translateY(-18vh) scale(0.97); }
  92% {
    transform: translateX(48vw) translateY(35vh) scale(0.71); }
  96% {
    transform: translateX(-48vw) translateY(43vh) scale(0.35); }
  100% {
    transform: translateX(-26vw) translateY(8vh) scale(0.28); } }

.firefly:nth-child(18) {
  animation-name: move18; }
  .firefly:nth-child(18)::before {
    animation-duration: 17s; }
  .firefly:nth-child(18)::after {
    animation-duration: 17s, 10486ms;
    animation-delay: 0ms, 7835ms; }

@keyframes move18 {
  0% {
    transform: translateX(34vw) translateY(-20vh) scale(1); }
  5% {
    transform: translateX(4vw) translateY(15vh) scale(0.5); }
  10% {
    transform: translateX(45vw) translateY(36vh) scale(0.94); }
  15% {
    transform: translateX(16vw) translateY(-5vh) scale(0.77); }
  20% {
    transform: translateX(20vw) translateY(-39vh) scale(0.38); }
  25% {
    transform: translateX(39vw) translateY(-47vh) scale(0.6); }
  30% {
    transform: translateX(-37vw) translateY(10vh) scale(0.32); }
  35% {
    transform: translateX(14vw) translateY(-44vh) scale(0.92); }
  40% {
    transform: translateX(43vw) translateY(1vh) scale(0.93); }
  45% {
    transform: translateX(-6vw) translateY(38vh) scale(0.49); }
  50% {
    transform: translateX(7vw) translateY(-1vh) scale(0.56); }
  55% {
    transform: translateX(22vw) translateY(36vh) scale(0.88); }
  60% {
    transform: translateX(-7vw) translateY(3vh) scale(0.34); }
  65% {
    transform: translateX(-21vw) translateY(-23vh) scale(0.98); }
  70% {
    transform: translateX(13vw) translateY(44vh) scale(0.75); }
  75% {
    transform: translateX(18vw) translateY(29vh) scale(0.77); }
  80% {
    transform: translateX(-14vw) translateY(24vh) scale(0.68); }
  85% {
    transform: translateX(-44vw) translateY(-12vh) scale(0.34); }
  90% {
    transform: translateX(34vw) translateY(28vh) scale(0.96); }
  95% {
    transform: translateX(15vw) translateY(-19vh) scale(0.49); }
  100% {
    transform: translateX(-48vw) translateY(-10vh) scale(0.99); } }

.firefly:nth-child(19) {
  animation-name: move19; }
  .firefly:nth-child(19)::before {
    animation-duration: 9s; }
  .firefly:nth-child(19)::after {
    animation-duration: 9s, 5429ms;
    animation-delay: 0ms, 1009ms; }

@keyframes move19 {
  0% {
    transform: translateX(-40vw) translateY(-43vh) scale(0.56); }
  5% {
    transform: translateX(21vw) translateY(13vh) scale(0.36); }
  10% {
    transform: translateX(-23vw) translateY(23vh) scale(0.78); }
  15% {
    transform: translateX(-2vw) translateY(37vh) scale(0.5); }
  20% {
    transform: translateX(39vw) translateY(35vh) scale(0.89); }
  25% {
    transform: translateX(-28vw) translateY(-5vh) scale(0.5); }
  30% {
    transform: translateX(3vw) translateY(49vh) scale(0.95); }
  35% {
    transform: translateX(48vw) translateY(-10vh) scale(0.9); }
  40% {
    transform: translateX(18vw) translateY(-49vh) scale(0.92); }
  45% {
    transform: translateX(40vw) translateY(-11vh) scale(0.51); }
  50% {
    transform: translateX(-6vw) translateY(-15vh) scale(0.95); }
  55% {
    transform: translateX(36vw) translateY(45vh) scale(0.9); }
  60% {
    transform: translateX(-38vw) translateY(44vh) scale(0.27); }
  65% {
    transform: translateX(-13vw) translateY(-23vh) scale(0.93); }
  70% {
    transform: translateX(25vw) translateY(-6vh) scale(0.93); }
  75% {
    transform: translateX(-3vw) translateY(34vh) scale(0.47); }
  80% {
    transform: translateX(-1vw) translateY(-5vh) scale(0.33); }
  85% {
    transform: translateX(-49vw) translateY(14vh) scale(0.29); }
  90% {
    transform: translateX(-30vw) translateY(-35vh) scale(0.49); }
  95% {
    transform: translateX(48vw) translateY(-32vh) scale(0.8); }
  100% {
    transform: translateX(-4vw) translateY(27vh) scale(0.39); } }

.firefly:nth-child(20) {
  animation-name: move20; }
  .firefly:nth-child(20)::before {
    animation-duration: 11s; }
  .firefly:nth-child(20)::after {
    animation-duration: 11s, 7213ms;
    animation-delay: 0ms, 1285ms; }

@keyframes move20 {
  0% {
    transform: translateX(32vw) translateY(40vh) scale(0.92); }
  5% {
    transform: translateX(-19vw) translateY(-15vh) scale(0.98); }
  10% {
    transform: translateX(-32vw) translateY(-43vh) scale(0.79); }
  15% {
    transform: translateX(-15vw) translateY(-17vh) scale(0.43); }
  20% {
    transform: translateX(38vw) translateY(32vh) scale(0.71); }
  25% {
    transform: translateX(-28vw) translateY(-31vh) scale(1); }
  30% {
    transform: translateX(-42vw) translateY(-25vh) scale(0.88); }
  35% {
    transform: translateX(24vw) translateY(-26vh) scale(0.72); }
  40% {
    transform: translateX(13vw) translateY(-2vh) scale(0.53); }
  45% {
    transform: translateX(-30vw) translateY(5vh) scale(0.95); }
  50% {
    transform: translateX(48vw) translateY(10vh) scale(0.73); }
  55% {
    transform: translateX(-8vw) translateY(-27vh) scale(0.49); }
  60% {
    transform: translateX(33vw) translateY(-19vh) scale(0.45); }
  65% {
    transform: translateX(-9vw) translateY(-47vh) scale(0.56); }
  70% {
    transform: translateX(-34vw) translateY(-7vh) scale(0.94); }
  75% {
    transform: translateX(47vw) translateY(31vh) scale(0.29); }
  80% {
    transform: translateX(-21vw) translateY(-37vh) scale(0.73); }
  85% {
    transform: translateX(34vw) translateY(-32vh) scale(0.6); }
  90% {
    transform: translateX(4vw) translateY(-18vh) scale(0.33); }
  95% {
    transform: translateX(47vw) translateY(-26vh) scale(0.56); }
  100% {
    transform: translateX(41vw) translateY(47vh) scale(0.29); } }

.firefly:nth-child(21) {
  animation-name: move21; }
  .firefly:nth-child(21)::before {
    animation-duration: 15s; }
  .firefly:nth-child(21)::after {
    animation-duration: 15s, 5848ms;
    animation-delay: 0ms, 1005ms; }

@keyframes move21 {
  0% {
    transform: translateX(-14vw) translateY(11vh) scale(0.35); }
  4.54545% {
    transform: translateX(-49vw) translateY(-35vh) scale(0.59); }
  9.09091% {
    transform: translateX(-13vw) translateY(19vh) scale(0.91); }
  13.63636% {
    transform: translateX(-35vw) translateY(-22vh) scale(0.7); }
  18.18182% {
    transform: translateX(-4vw) translateY(-35vh) scale(0.76); }
  22.72727% {
    transform: translateX(3vw) translateY(15vh) scale(0.27); }
  27.27273% {
    transform: translateX(36vw) translateY(-11vh) scale(0.78); }
  31.81818% {
    transform: translateX(0vw) translateY(-18vh) scale(0.67); }
  36.36364% {
    transform: translateX(34vw) translateY(-3vh) scale(0.91); }
  40.90909% {
    transform: translateX(1vw) translateY(-20vh) scale(0.45); }
  45.45455% {
    transform: translateX(-26vw) translateY(-47vh) scale(0.98); }
  50% {
    transform: translateX(-13vw) translateY(-2vh) scale(0.66); }
  54.54545% {
    transform: translateX(-38vw) translateY(33vh) scale(0.54); }
  59.09091% {
    transform: translateX(20vw) translateY(16vh) scale(0.96); }
  63.63636% {
    transform: translateX(5vw) translateY(41vh) scale(0.78); }
  68.18182% {
    transform: translateX(-9vw) translateY(-8vh) scale(0.98); }
  72.72727% {
    transform: translateX(0vw) translateY(-35vh) scale(0.61); }
  77.27273% {
    transform: translateX(8vw) translateY(-26vh) scale(0.94); }
  81.81818% {
    transform: translateX(-2vw) translateY(29vh) scale(0.7); }
  86.36364% {
    transform: translateX(-22vw) translateY(-16vh) scale(0.68); }
  90.90909% {
    transform: translateX(16vw) translateY(23vh) scale(0.9); }
  95.45455% {
    transform: translateX(21vw) translateY(50vh) scale(0.9); }
  100% {
    transform: translateX(-11vw) translateY(-2vh) scale(0.76); } }

.firefly:nth-child(22) {
  animation-name: move22; }
  .firefly:nth-child(22)::before {
    animation-duration: 16s; }
  .firefly:nth-child(22)::after {
    animation-duration: 16s, 5242ms;
    animation-delay: 0ms, 5738ms; }

@keyframes move22 {
  0% {
    transform: translateX(-35vw) translateY(31vh) scale(0.33); }
  3.84615% {
    transform: translateX(2vw) translateY(4vh) scale(0.75); }
  7.69231% {
    transform: translateX(-2vw) translateY(-9vh) scale(0.42); }
  11.53846% {
    transform: translateX(26vw) translateY(-27vh) scale(0.83); }
  15.38462% {
    transform: translateX(7vw) translateY(16vh) scale(0.65); }
  19.23077% {
    transform: translateX(36vw) translateY(-8vh) scale(0.69); }
  23.07692% {
    transform: translateX(40vw) translateY(-11vh) scale(0.7); }
  26.92308% {
    transform: translateX(-32vw) translateY(-13vh) scale(0.48); }
  30.76923% {
    transform: translateX(-45vw) translateY(24vh) scale(0.83); }
  34.61538% {
    transform: translateX(19vw) translateY(30vh) scale(0.73); }
  38.46154% {
    transform: translateX(15vw) translateY(38vh) scale(0.46); }
  42.30769% {
    transform: translateX(-2vw) translateY(-38vh) scale(0.44); }
  46.15385% {
    transform: translateX(1vw) translateY(15vh) scale(0.35); }
  50% {
    transform: translateX(34vw) translateY(-46vh) scale(0.91); }
  53.84615% {
    transform: translateX(-44vw) translateY(-39vh) scale(0.58); }
  57.69231% {
    transform: translateX(38vw) translateY(-39vh) scale(0.79); }
  61.53846% {
    transform: translateX(21vw) translateY(-20vh) scale(0.49); }
  65.38462% {
    transform: translateX(31vw) translateY(50vh) scale(0.4); }
  69.23077% {
    transform: translateX(-17vw) translateY(-38vh) scale(0.3); }
  73.07692% {
    transform: translateX(-18vw) translateY(-10vh) scale(0.93); }
  76.92308% {
    transform: translateX(38vw) translateY(1vh) scale(0.63); }
  80.76923% {
    transform: translateX(-31vw) translateY(-7vh) scale(0.58); }
  84.61538% {
    transform: translateX(-15vw) translateY(-26vh) scale(0.52); }
  88.46154% {
    transform: translateX(10vw) translateY(-39vh) scale(0.49); }
  92.30769% {
    transform: translateX(16vw) translateY(7vh) scale(0.47); }
  96.15385% {
    transform: translateX(-32vw) translateY(3vh) scale(0.9); }
  100% {
    transform: translateX(-46vw) translateY(12vh) scale(0.52); } }

.firefly:nth-child(23) {
  animation-name: move23; }
  .firefly:nth-child(23)::before {
    animation-duration: 9s; }
  .firefly:nth-child(23)::after {
    animation-duration: 9s, 10506ms;
    animation-delay: 0ms, 5855ms; }

@keyframes move23 {
  0% {
    transform: translateX(-4vw) translateY(0vh) scale(0.9); }
  3.57143% {
    transform: translateX(-13vw) translateY(-28vh) scale(0.27); }
  7.14286% {
    transform: translateX(-47vw) translateY(1vh) scale(0.84); }
  10.71429% {
    transform: translateX(-17vw) translateY(46vh) scale(0.74); }
  14.28571% {
    transform: translateX(50vw) translateY(-12vh) scale(0.32); }
  17.85714% {
    transform: translateX(-1vw) translateY(0vh) scale(0.26); }
  21.42857% {
    transform: translateX(-38vw) translateY(-45vh) scale(0.27); }
  25% {
    transform: translateX(-39vw) translateY(-49vh) scale(0.91); }
  28.57143% {
    transform: translateX(44vw) translateY(42vh) scale(0.87); }
  32.14286% {
    transform: translateX(39vw) translateY(-8vh) scale(0.64); }
  35.71429% {
    transform: translateX(-49vw) translateY(-25vh) scale(0.43); }
  39.28571% {
    transform: translateX(42vw) translateY(-30vh) scale(0.74); }
  42.85714% {
    transform: translateX(-36vw) translateY(-47vh) scale(0.7); }
  46.42857% {
    transform: translateX(-39vw) translateY(35vh) scale(0.99); }
  50% {
    transform: translateX(-24vw) translateY(-9vh) scale(0.43); }
  53.57143% {
    transform: translateX(9vw) translateY(28vh) scale(0.84); }
  57.14286% {
    transform: translateX(-2vw) translateY(21vh) scale(0.26); }
  60.71429% {
    transform: translateX(20vw) translateY(-22vh) scale(0.49); }
  64.28571% {
    transform: translateX(1vw) translateY(-37vh) scale(0.52); }
  67.85714% {
    transform: translateX(43vw) translateY(42vh) scale(0.96); }
  71.42857% {
    transform: translateX(30vw) translateY(-5vh) scale(0.96); }
  75% {
    transform: translateX(1vw) translateY(13vh) scale(0.65); }
  78.57143% {
    transform: translateX(-45vw) translateY(-16vh) scale(0.96); }
  82.14286% {
    transform: translateX(18vw) translateY(-44vh) scale(0.47); }
  85.71429% {
    transform: translateX(47vw) translateY(-7vh) scale(0.79); }
  89.28571% {
    transform: translateX(49vw) translateY(-7vh) scale(0.88); }
  92.85714% {
    transform: translateX(-4vw) translateY(50vh) scale(0.7); }
  96.42857% {
    transform: translateX(24vw) translateY(43vh) scale(0.46); }
  100% {
    transform: translateX(7vw) translateY(14vh) scale(0.86); } }

.firefly:nth-child(24) {
  animation-name: move24; }
  .firefly:nth-child(24)::before {
    animation-duration: 16s; }
  .firefly:nth-child(24)::after {
    animation-duration: 16s, 6120ms;
    animation-delay: 0ms, 2289ms; }

@keyframes move24 {
  0% {
    transform: translateX(31vw) translateY(-2vh) scale(0.69); }
  5.88235% {
    transform: translateX(-48vw) translateY(6vh) scale(0.63); }
  11.76471% {
    transform: translateX(0vw) translateY(4vh) scale(0.57); }
  17.64706% {
    transform: translateX(25vw) translateY(-11vh) scale(0.4); }
  23.52941% {
    transform: translateX(-10vw) translateY(41vh) scale(0.3); }
  29.41176% {
    transform: translateX(15vw) translateY(48vh) scale(0.93); }
  35.29412% {
    transform: translateX(-6vw) translateY(4vh) scale(0.29); }
  41.17647% {
    transform: translateX(21vw) translateY(-18vh) scale(0.59); }
  47.05882% {
    transform: translateX(37vw) translateY(31vh) scale(0.47); }
  52.94118% {
    transform: translateX(-20vw) translateY(27vh) scale(0.73); }
  58.82353% {
    transform: translateX(-22vw) translateY(32vh) scale(0.27); }
  64.70588% {
    transform: translateX(-21vw) translateY(6vh) scale(0.91); }
  70.58824% {
    transform: translateX(30vw) translateY(29vh) scale(0.49); }
  76.47059% {
    transform: translateX(-48vw) translateY(-12vh) scale(0.48); }
  82.35294% {
    transform: translateX(14vw) translateY(-31vh) scale(0.59); }
  88.23529% {
    transform: translateX(-10vw) translateY(21vh) scale(0.57); }
  94.11765% {
    transform: translateX(0vw) translateY(-6vh) scale(0.42); }
  100% {
    transform: translateX(36vw) translateY(15vh) scale(0.95); } }

.firefly:nth-child(25) {
  animation-name: move25; }
  .firefly:nth-child(25)::before {
    animation-duration: 11s; }
  .firefly:nth-child(25)::after {
    animation-duration: 11s, 7612ms;
    animation-delay: 0ms, 4429ms; }

@keyframes move25 {
  0% {
    transform: translateX(8vw) translateY(29vh) scale(1); }
  4.34783% {
    transform: translateX(12vw) translateY(24vh) scale(0.95); }
  8.69565% {
    transform: translateX(-32vw) translateY(4vh) scale(0.47); }
  13.04348% {
    transform: translateX(27vw) translateY(-28vh) scale(0.91); }
  17.3913% {
    transform: translateX(-46vw) translateY(-45vh) scale(0.75); }
  21.73913% {
    transform: translateX(10vw) translateY(46vh) scale(0.61); }
  26.08696% {
    transform: translateX(0vw) translateY(46vh) scale(0.29); }
  30.43478% {
    transform: translateX(14vw) translateY(-37vh) scale(0.51); }
  34.78261% {
    transform: translateX(0vw) translateY(14vh) scale(0.91); }
  39.13043% {
    transform: translateX(-34vw) translateY(45vh) scale(0.64); }
  43.47826% {
    transform: translateX(23vw) translateY(0vh) scale(0.92); }
  47.82609% {
    transform: translateX(32vw) translateY(27vh) scale(0.4); }
  52.17391% {
    transform: translateX(46vw) translateY(0vh) scale(0.48); }
  56.52174% {
    transform: translateX(-26vw) translateY(-22vh) scale(0.85); }
  60.86957% {
    transform: translateX(14vw) translateY(46vh) scale(0.71); }
  65.21739% {
    transform: translateX(-28vw) translateY(32vh) scale(0.33); }
  69.56522% {
    transform: translateX(29vw) translateY(-49vh) scale(0.4); }
  73.91304% {
    transform: translateX(6vw) translateY(22vh) scale(0.44); }
  78.26087% {
    transform: translateX(-25vw) translateY(45vh) scale(0.53); }
  82.6087% {
    transform: translateX(4vw) translateY(-3vh) scale(0.26); }
  86.95652% {
    transform: translateX(-46vw) translateY(35vh) scale(0.32); }
  91.30435% {
    transform: translateX(50vw) translateY(-29vh) scale(0.85); }
  95.65217% {
    transform: translateX(4vw) translateY(5vh) scale(0.7); }
  100% {
    transform: translateX(-33vw) translateY(22vh) scale(0.36); } }

.firefly:nth-child(26) {
  animation-name: move26; }
  .firefly:nth-child(26)::before {
    animation-duration: 15s; }
  .firefly:nth-child(26)::after {
    animation-duration: 15s, 8105ms;
    animation-delay: 0ms, 5773ms; }

@keyframes move26 {
  0% {
    transform: translateX(-46vw) translateY(-36vh) scale(0.99); }
  5.26316% {
    transform: translateX(4vw) translateY(45vh) scale(0.47); }
  10.52632% {
    transform: translateX(-9vw) translateY(-29vh) scale(0.33); }
  15.78947% {
    transform: translateX(-47vw) translateY(-24vh) scale(0.65); }
  21.05263% {
    transform: translateX(27vw) translateY(22vh) scale(0.64); }
  26.31579% {
    transform: translateX(36vw) translateY(42vh) scale(0.6); }
  31.57895% {
    transform: translateX(1vw) translateY(-35vh) scale(0.41); }
  36.84211% {
    transform: translateX(-14vw) translateY(7vh) scale(0.41); }
  42.10526% {
    transform: translateX(47vw) translateY(2vh) scale(0.53); }
  47.36842% {
    transform: translateX(-6vw) translateY(4vh) scale(0.36); }
  52.63158% {
    transform: translateX(-40vw) translateY(-46vh) scale(0.7); }
  57.89474% {
    transform: translateX(-27vw) translateY(-15vh) scale(0.57); }
  63.15789% {
    transform: translateX(15vw) translateY(-43vh) scale(0.38); }
  68.42105% {
    transform: translateX(33vw) translateY(-45vh) scale(0.82); }
  73.68421% {
    transform: translateX(39vw) translateY(-6vh) scale(0.93); }
  78.94737% {
    transform: translateX(-15vw) translateY(12vh) scale(0.75); }
  84.21053% {
    transform: translateX(43vw) translateY(47vh) scale(0.64); }
  89.47368% {
    transform: translateX(-22vw) translateY(28vh) scale(0.29); }
  94.73684% {
    transform: translateX(-12vw) translateY(-18vh) scale(0.64); }
  100% {
    transform: translateX(43vw) translateY(50vh) scale(0.69); } }

.firefly:nth-child(27) {
  animation-name: move27; }
  .firefly:nth-child(27)::before {
    animation-duration: 12s; }
  .firefly:nth-child(27)::after {
    animation-duration: 12s, 6172ms;
    animation-delay: 0ms, 8318ms; }

@keyframes move27 {
  0% {
    transform: translateX(-48vw) translateY(-1vh) scale(0.86); }
  4.16667% {
    transform: translateX(44vw) translateY(-11vh) scale(0.45); }
  8.33333% {
    transform: translateX(-48vw) translateY(-49vh) scale(0.46); }
  12.5% {
    transform: translateX(-16vw) translateY(-6vh) scale(0.86); }
  16.66667% {
    transform: translateX(-24vw) translateY(12vh) scale(0.57); }
  20.83333% {
    transform: translateX(-25vw) translateY(42vh) scale(0.56); }
  25% {
    transform: translateX(46vw) translateY(-46vh) scale(0.36); }
  29.16667% {
    transform: translateX(-26vw) translateY(-9vh) scale(0.67); }
  33.33333% {
    transform: translateX(-46vw) translateY(-17vh) scale(0.46); }
  37.5% {
    transform: translateX(43vw) translateY(9vh) scale(0.64); }
  41.66667% {
    transform: translateX(-21vw) translateY(21vh) scale(0.89); }
  45.83333% {
    transform: translateX(-32vw) translateY(15vh) scale(0.29); }
  50% {
    transform: translateX(49vw) translateY(4vh) scale(0.81); }
  54.16667% {
    transform: translateX(31vw) translateY(24vh) scale(0.49); }
  58.33333% {
    transform: translateX(39vw) translateY(18vh) scale(0.32); }
  62.5% {
    transform: translateX(49vw) translateY(50vh) scale(0.37); }
  66.66667% {
    transform: translateX(-13vw) translateY(-32vh) scale(0.44); }
  70.83333% {
    transform: translateX(-31vw) translateY(34vh) scale(0.99); }
  75% {
    transform: translateX(-24vw) translateY(45vh) scale(0.86); }
  79.16667% {
    transform: translateX(26vw) translateY(14vh) scale(0.93); }
  83.33333% {
    transform: translateX(-47vw) translateY(16vh) scale(0.61); }
  87.5% {
    transform: translateX(6vw) translateY(27vh) scale(0.98); }
  91.66667% {
    transform: translateX(4vw) translateY(-19vh) scale(0.52); }
  95.83333% {
    transform: translateX(-18vw) translateY(-24vh) scale(0.64); }
  100% {
    transform: translateX(10vw) translateY(48vh) scale(0.32); } }

.firefly:nth-child(28) {
  animation-name: move28; }
  .firefly:nth-child(28)::before {
    animation-duration: 9s; }
  .firefly:nth-child(28)::after {
    animation-duration: 9s, 8250ms;
    animation-delay: 0ms, 6273ms; }

@keyframes move28 {
  0% {
    transform: translateX(32vw) translateY(-46vh) scale(0.78); }
  4.16667% {
    transform: translateX(-1vw) translateY(0vh) scale(0.48); }
  8.33333% {
    transform: translateX(-15vw) translateY(-9vh) scale(0.71); }
  12.5% {
    transform: translateX(-45vw) translateY(38vh) scale(0.53); }
  16.66667% {
    transform: translateX(-11vw) translateY(-22vh) scale(0.38); }
  20.83333% {
    transform: translateX(3vw) translateY(-4vh) scale(0.6); }
  25% {
    transform: translateX(-34vw) translateY(6vh) scale(0.59); }
  29.16667% {
    transform: translateX(31vw) translateY(-41vh) scale(0.26); }
  33.33333% {
    transform: translateX(-10vw) translateY(40vh) scale(0.46); }
  37.5% {
    transform: translateX(50vw) translateY(47vh) scale(0.99); }
  41.66667% {
    transform: translateX(-44vw) translateY(-42vh) scale(0.92); }
  45.83333% {
    transform: translateX(-43vw) translateY(-7vh) scale(0.62); }
  50% {
    transform: translateX(31vw) translateY(-20vh) scale(0.28); }
  54.16667% {
    transform: translateX(-13vw) translateY(8vh) scale(0.64); }
  58.33333% {
    transform: translateX(34vw) translateY(20vh) scale(0.31); }
  62.5% {
    transform: translateX(-46vw) translateY(21vh) scale(0.64); }
  66.66667% {
    transform: translateX(31vw) translateY(25vh) scale(0.28); }
  70.83333% {
    transform: translateX(1vw) translateY(21vh) scale(0.26); }
  75% {
    transform: translateX(-15vw) translateY(1vh) scale(0.87); }
  79.16667% {
    transform: translateX(39vw) translateY(37vh) scale(0.56); }
  83.33333% {
    transform: translateX(8vw) translateY(-45vh) scale(0.53); }
  87.5% {
    transform: translateX(-38vw) translateY(-43vh) scale(0.8); }
  91.66667% {
    transform: translateX(-38vw) translateY(-4vh) scale(0.81); }
  95.83333% {
    transform: translateX(35vw) translateY(29vh) scale(0.56); }
  100% {
    transform: translateX(-42vw) translateY(-29vh) scale(0.99); } }

.firefly:nth-child(29) {
  animation-name: move29; }
  .firefly:nth-child(29)::before {
    animation-duration: 17s; }
  .firefly:nth-child(29)::after {
    animation-duration: 17s, 9871ms;
    animation-delay: 0ms, 1071ms; }

@keyframes move29 {
  0% {
    transform: translateX(25vw) translateY(18vh) scale(0.65); }
  5.88235% {
    transform: translateX(44vw) translateY(-9vh) scale(0.84); }
  11.76471% {
    transform: translateX(35vw) translateY(-25vh) scale(0.58); }
  17.64706% {
    transform: translateX(34vw) translateY(30vh) scale(0.4); }
  23.52941% {
    transform: translateX(32vw) translateY(-21vh) scale(0.82); }
  29.41176% {
    transform: translateX(-34vw) translateY(42vh) scale(0.36); }
  35.29412% {
    transform: translateX(-5vw) translateY(1vh) scale(0.3); }
  41.17647% {
    transform: translateX(-42vw) translateY(-7vh) scale(0.35); }
  47.05882% {
    transform: translateX(18vw) translateY(-20vh) scale(0.84); }
  52.94118% {
    transform: translateX(0vw) translateY(13vh) scale(0.58); }
  58.82353% {
    transform: translateX(50vw) translateY(27vh) scale(0.5); }
  64.70588% {
    transform: translateX(27vw) translateY(50vh) scale(0.32); }
  70.58824% {
    transform: translateX(2vw) translateY(-3vh) scale(0.29); }
  76.47059% {
    transform: translateX(-32vw) translateY(-13vh) scale(0.78); }
  82.35294% {
    transform: translateX(11vw) translateY(10vh) scale(0.6); }
  88.23529% {
    transform: translateX(-39vw) translateY(-12vh) scale(0.86); }
  94.11765% {
    transform: translateX(-9vw) translateY(-44vh) scale(0.54); }
  100% {
    transform: translateX(-36vw) translateY(32vh) scale(0.84); } }

.firefly:nth-child(30) {
  animation-name: move30; }
  .firefly:nth-child(30)::before {
    animation-duration: 15s; }
  .firefly:nth-child(30)::after {
    animation-duration: 15s, 6134ms;
    animation-delay: 0ms, 5023ms; }

@keyframes move30 {
  0% {
    transform: translateX(-40vw) translateY(49vh) scale(0.89); }
  4.54545% {
    transform: translateX(-11vw) translateY(-36vh) scale(0.79); }
  9.09091% {
    transform: translateX(49vw) translateY(-25vh) scale(0.89); }
  13.63636% {
    transform: translateX(49vw) translateY(-21vh) scale(0.87); }
  18.18182% {
    transform: translateX(7vw) translateY(11vh) scale(0.5); }
  22.72727% {
    transform: translateX(-46vw) translateY(41vh) scale(0.77); }
  27.27273% {
    transform: translateX(-36vw) translateY(-28vh) scale(0.42); }
  31.81818% {
    transform: translateX(26vw) translateY(41vh) scale(0.51); }
  36.36364% {
    transform: translateX(9vw) translateY(0vh) scale(0.89); }
  40.90909% {
    transform: translateX(9vw) translateY(-48vh) scale(0.93); }
  45.45455% {
    transform: translateX(4vw) translateY(23vh) scale(0.52); }
  50% {
    transform: translateX(19vw) translateY(-17vh) scale(0.95); }
  54.54545% {
    transform: translateX(7vw) translateY(26vh) scale(0.39); }
  59.09091% {
    transform: translateX(25vw) translateY(8vh) scale(0.4); }
  63.63636% {
    transform: translateX(44vw) translateY(33vh) scale(0.38); }
  68.18182% {
    transform: translateX(-45vw) translateY(-48vh) scale(0.52); }
  72.72727% {
    transform: translateX(33vw) translateY(33vh) scale(0.86); }
  77.27273% {
    transform: translateX(-45vw) translateY(24vh) scale(0.98); }
  81.81818% {
    transform: translateX(-5vw) translateY(37vh) scale(0.48); }
  86.36364% {
    transform: translateX(-28vw) translateY(41vh) scale(0.4); }
  90.90909% {
    transform: translateX(35vw) translateY(7vh) scale(0.7); }
  95.45455% {
    transform: translateX(8vw) translateY(-7vh) scale(0.45); }
  100% {
    transform: translateX(-8vw) translateY(-20vh) scale(0.85); } }

.firefly:nth-child(31) {
  animation-name: move31; }
  .firefly:nth-child(31)::before {
    animation-duration: 12s; }
  .firefly:nth-child(31)::after {
    animation-duration: 12s, 5612ms;
    animation-delay: 0ms, 7654ms; }

@keyframes move31 {
  0% {
    transform: translateX(31vw) translateY(-46vh) scale(0.85); }
  5% {
    transform: translateX(-49vw) translateY(-11vh) scale(0.39); }
  10% {
    transform: translateX(-10vw) translateY(9vh) scale(0.55); }
  15% {
    transform: translateX(-10vw) translateY(-5vh) scale(0.89); }
  20% {
    transform: translateX(-27vw) translateY(-45vh) scale(0.57); }
  25% {
    transform: translateX(1vw) translateY(1vh) scale(0.36); }
  30% {
    transform: translateX(-6vw) translateY(-42vh) scale(0.92); }
  35% {
    transform: translateX(17vw) translateY(-43vh) scale(0.7); }
  40% {
    transform: translateX(25vw) translateY(39vh) scale(0.9); }
  45% {
    transform: translateX(-35vw) translateY(-21vh) scale(0.51); }
  50% {
    transform: translateX(13vw) translateY(44vh) scale(0.89); }
  55% {
    transform: translateX(-20vw) translateY(22vh) scale(0.59); }
  60% {
    transform: translateX(-37vw) translateY(1vh) scale(0.7); }
  65% {
    transform: translateX(-6vw) translateY(26vh) scale(0.28); }
  70% {
    transform: translateX(22vw) translateY(11vh) scale(0.29); }
  75% {
    transform: translateX(16vw) translateY(-20vh) scale(0.57); }
  80% {
    transform: translateX(-45vw) translateY(-18vh) scale(0.3); }
  85% {
    transform: translateX(2vw) translateY(13vh) scale(0.81); }
  90% {
    transform: translateX(-24vw) translateY(36vh) scale(0.48); }
  95% {
    transform: translateX(20vw) translateY(-39vh) scale(0.48); }
  100% {
    transform: translateX(7vw) translateY(21vh) scale(0.37); } }

.firefly:nth-child(32) {
  animation-name: move32; }
  .firefly:nth-child(32)::before {
    animation-duration: 11s; }
  .firefly:nth-child(32)::after {
    animation-duration: 11s, 9869ms;
    animation-delay: 0ms, 1800ms; }

@keyframes move32 {
  0% {
    transform: translateX(20vw) translateY(19vh) scale(0.61); }
  5% {
    transform: translateX(-10vw) translateY(10vh) scale(0.71); }
  10% {
    transform: translateX(-28vw) translateY(-15vh) scale(0.86); }
  15% {
    transform: translateX(-6vw) translateY(-18vh) scale(0.58); }
  20% {
    transform: translateX(-1vw) translateY(-5vh) scale(0.75); }
  25% {
    transform: translateX(48vw) translateY(-49vh) scale(0.73); }
  30% {
    transform: translateX(31vw) translateY(7vh) scale(0.33); }
  35% {
    transform: translateX(36vw) translateY(4vh) scale(0.75); }
  40% {
    transform: translateX(-48vw) translateY(10vh) scale(0.53); }
  45% {
    transform: translateX(39vw) translateY(-28vh) scale(0.52); }
  50% {
    transform: translateX(-45vw) translateY(-37vh) scale(0.44); }
  55% {
    transform: translateX(-45vw) translateY(38vh) scale(0.85); }
  60% {
    transform: translateX(0vw) translateY(48vh) scale(0.57); }
  65% {
    transform: translateX(-46vw) translateY(38vh) scale(0.84); }
  70% {
    transform: translateX(13vw) translateY(29vh) scale(0.44); }
  75% {
    transform: translateX(28vw) translateY(50vh) scale(0.27); }
  80% {
    transform: translateX(-14vw) translateY(32vh) scale(0.95); }
  85% {
    transform: translateX(-48vw) translateY(-21vh) scale(0.79); }
  90% {
    transform: translateX(-30vw) translateY(36vh) scale(0.73); }
  95% {
    transform: translateX(43vw) translateY(-25vh) scale(0.29); }
  100% {
    transform: translateX(-5vw) translateY(-18vh) scale(0.38); } }

.firefly:nth-child(33) {
  animation-name: move33; }
  .firefly:nth-child(33)::before {
    animation-duration: 15s; }
  .firefly:nth-child(33)::after {
    animation-duration: 15s, 10658ms;
    animation-delay: 0ms, 1205ms; }

@keyframes move33 {
  0% {
    transform: translateX(25vw) translateY(42vh) scale(0.92); }
  3.7037% {
    transform: translateX(-17vw) translateY(-46vh) scale(0.96); }
  7.40741% {
    transform: translateX(-6vw) translateY(24vh) scale(0.27); }
  11.11111% {
    transform: translateX(43vw) translateY(-27vh) scale(0.98); }
  14.81481% {
    transform: translateX(6vw) translateY(-27vh) scale(0.47); }
  18.51852% {
    transform: translateX(3vw) translateY(50vh) scale(0.63); }
  22.22222% {
    transform: translateX(-46vw) translateY(26vh) scale(0.44); }
  25.92593% {
    transform: translateX(-49vw) translateY(38vh) scale(0.47); }
  29.62963% {
    transform: translateX(25vw) translateY(-49vh) scale(0.51); }
  33.33333% {
    transform: translateX(3vw) translateY(17vh) scale(0.73); }
  37.03704% {
    transform: translateX(-49vw) translateY(38vh) scale(0.89); }
  40.74074% {
    transform: translateX(-8vw) translateY(-39vh) scale(0.96); }
  44.44444% {
    transform: translateX(18vw) translateY(30vh) scale(0.97); }
  48.14815% {
    transform: translateX(13vw) translateY(-40vh) scale(0.95); }
  51.85185% {
    transform: translateX(36vw) translateY(-4vh) scale(0.4); }
  55.55556% {
    transform: translateX(20vw) translateY(42vh) scale(0.32); }
  59.25926% {
    transform: translateX(-43vw) translateY(-46vh) scale(0.56); }
  62.96296% {
    transform: translateX(-46vw) translateY(28vh) scale(0.63); }
  66.66667% {
    transform: translateX(-27vw) translateY(-41vh) scale(0.83); }
  70.37037% {
    transform: translateX(-15vw) translateY(44vh) scale(0.37); }
  74.07407% {
    transform: translateX(2vw) translateY(-32vh) scale(0.97); }
  77.77778% {
    transform: translateX(18vw) translateY(11vh) scale(0.79); }
  81.48148% {
    transform: translateX(7vw) translateY(11vh) scale(0.92); }
  85.18519% {
    transform: translateX(-25vw) translateY(-7vh) scale(0.46); }
  88.88889% {
    transform: translateX(42vw) translateY(-20vh) scale(0.32); }
  92.59259% {
    transform: translateX(-35vw) translateY(20vh) scale(0.46); }
  96.2963% {
    transform: translateX(25vw) translateY(-26vh) scale(0.31); }
  100% {
    transform: translateX(9vw) translateY(-46vh) scale(0.43); } }

.firefly:nth-child(34) {
  animation-name: move34; }
  .firefly:nth-child(34)::before {
    animation-duration: 17s; }
  .firefly:nth-child(34)::after {
    animation-duration: 17s, 6604ms;
    animation-delay: 0ms, 7900ms; }

@keyframes move34 {
  0% {
    transform: translateX(21vw) translateY(-20vh) scale(0.32); }
  4.54545% {
    transform: translateX(-3vw) translateY(42vh) scale(0.38); }
  9.09091% {
    transform: translateX(-4vw) translateY(11vh) scale(0.39); }
  13.63636% {
    transform: translateX(38vw) translateY(33vh) scale(0.89); }
  18.18182% {
    transform: translateX(1vw) translateY(4vh) scale(0.66); }
  22.72727% {
    transform: translateX(-36vw) translateY(-34vh) scale(0.34); }
  27.27273% {
    transform: translateX(-12vw) translateY(20vh) scale(0.41); }
  31.81818% {
    transform: translateX(31vw) translateY(-38vh) scale(0.68); }
  36.36364% {
    transform: translateX(-28vw) translateY(-49vh) scale(0.58); }
  40.90909% {
    transform: translateX(35vw) translateY(-41vh) scale(0.31); }
  45.45455% {
    transform: translateX(25vw) translateY(-6vh) scale(0.81); }
  50% {
    transform: translateX(15vw) translateY(-21vh) scale(0.68); }
  54.54545% {
    transform: translateX(23vw) translateY(47vh) scale(0.7); }
  59.09091% {
    transform: translateX(9vw) translateY(16vh) scale(0.97); }
  63.63636% {
    transform: translateX(4vw) translateY(13vh) scale(0.94); }
  68.18182% {
    transform: translateX(28vw) translateY(24vh) scale(0.88); }
  72.72727% {
    transform: translateX(35vw) translateY(25vh) scale(0.51); }
  77.27273% {
    transform: translateX(-28vw) translateY(6vh) scale(0.35); }
  81.81818% {
    transform: translateX(-11vw) translateY(-2vh) scale(0.42); }
  86.36364% {
    transform: translateX(-47vw) translateY(-34vh) scale(0.87); }
  90.90909% {
    transform: translateX(19vw) translateY(14vh) scale(0.84); }
  95.45455% {
    transform: translateX(2vw) translateY(-7vh) scale(0.59); }
  100% {
    transform: translateX(0vw) translateY(-13vh) scale(0.39); } }

.firefly:nth-child(35) {
  animation-name: move35; }
  .firefly:nth-child(35)::before {
    animation-duration: 10s; }
  .firefly:nth-child(35)::after {
    animation-duration: 10s, 7680ms;
    animation-delay: 0ms, 1333ms; }

@keyframes move35 {
  0% {
    transform: translateX(-30vw) translateY(-2vh) scale(0.7); }
  5% {
    transform: translateX(13vw) translateY(-33vh) scale(0.83); }
  10% {
    transform: translateX(-36vw) translateY(-42vh) scale(0.79); }
  15% {
    transform: translateX(-8vw) translateY(-38vh) scale(0.84); }
  20% {
    transform: translateX(-45vw) translateY(-49vh) scale(0.93); }
  25% {
    transform: translateX(-3vw) translateY(-27vh) scale(0.78); }
  30% {
    transform: translateX(45vw) translateY(-9vh) scale(0.4); }
  35% {
    transform: translateX(-2vw) translateY(-29vh) scale(0.45); }
  40% {
    transform: translateX(-29vw) translateY(1vh) scale(0.73); }
  45% {
    transform: translateX(-46vw) translateY(-7vh) scale(0.65); }
  50% {
    transform: translateX(-13vw) translateY(-21vh) scale(0.35); }
  55% {
    transform: translateX(-30vw) translateY(26vh) scale(0.43); }
  60% {
    transform: translateX(-12vw) translateY(7vh) scale(0.94); }
  65% {
    transform: translateX(40vw) translateY(-39vh) scale(0.67); }
  70% {
    transform: translateX(6vw) translateY(-5vh) scale(0.91); }
  75% {
    transform: translateX(-6vw) translateY(-3vh) scale(0.96); }
  80% {
    transform: translateX(-21vw) translateY(-9vh) scale(0.49); }
  85% {
    transform: translateX(31vw) translateY(-49vh) scale(0.62); }
  90% {
    transform: translateX(37vw) translateY(-13vh) scale(0.77); }
  95% {
    transform: translateX(48vw) translateY(13vh) scale(0.59); }
  100% {
    transform: translateX(47vw) translateY(38vh) scale(0.39); } }

.firefly:nth-child(36) {
  animation-name: move36; }
  .firefly:nth-child(36)::before {
    animation-duration: 10s; }
  .firefly:nth-child(36)::after {
    animation-duration: 10s, 9494ms;
    animation-delay: 0ms, 8363ms; }

@keyframes move36 {
  0% {
    transform: translateX(31vw) translateY(-17vh) scale(0.51); }
  4.7619% {
    transform: translateX(-20vw) translateY(-33vh) scale(0.84); }
  9.52381% {
    transform: translateX(50vw) translateY(22vh) scale(0.53); }
  14.28571% {
    transform: translateX(-13vw) translateY(15vh) scale(0.56); }
  19.04762% {
    transform: translateX(3vw) translateY(1vh) scale(0.72); }
  23.80952% {
    transform: translateX(-45vw) translateY(-40vh) scale(0.63); }
  28.57143% {
    transform: translateX(-38vw) translateY(4vh) scale(0.41); }
  33.33333% {
    transform: translateX(-1vw) translateY(-9vh) scale(0.52); }
  38.09524% {
    transform: translateX(-8vw) translateY(38vh) scale(0.77); }
  42.85714% {
    transform: translateX(-26vw) translateY(-2vh) scale(0.79); }
  47.61905% {
    transform: translateX(-26vw) translateY(18vh) scale(0.34); }
  52.38095% {
    transform: translateX(-35vw) translateY(-33vh) scale(0.9); }
  57.14286% {
    transform: translateX(-12vw) translateY(-24vh) scale(0.35); }
  61.90476% {
    transform: translateX(33vw) translateY(-30vh) scale(0.47); }
  66.66667% {
    transform: translateX(-41vw) translateY(33vh) scale(0.74); }
  71.42857% {
    transform: translateX(2vw) translateY(-21vh) scale(0.62); }
  76.19048% {
    transform: translateX(25vw) translateY(18vh) scale(0.48); }
  80.95238% {
    transform: translateX(-48vw) translateY(23vh) scale(0.62); }
  85.71429% {
    transform: translateX(19vw) translateY(39vh) scale(0.31); }
  90.47619% {
    transform: translateX(-20vw) translateY(-2vh) scale(0.54); }
  95.2381% {
    transform: translateX(-41vw) translateY(-28vh) scale(0.72); }
  100% {
    transform: translateX(-49vw) translateY(12vh) scale(0.58); } }

.firefly:nth-child(37) {
  animation-name: move37; }
  .firefly:nth-child(37)::before {
    animation-duration: 12s; }
  .firefly:nth-child(37)::after {
    animation-duration: 12s, 6998ms;
    animation-delay: 0ms, 4594ms; }

@keyframes move37 {
  0% {
    transform: translateX(-21vw) translateY(31vh) scale(0.43); }
  5.55556% {
    transform: translateX(-8vw) translateY(42vh) scale(0.98); }
  11.11111% {
    transform: translateX(1vw) translateY(25vh) scale(0.7); }
  16.66667% {
    transform: translateX(36vw) translateY(-1vh) scale(0.99); }
  22.22222% {
    transform: translateX(-27vw) translateY(-4vh) scale(0.36); }
  27.77778% {
    transform: translateX(16vw) translateY(-18vh) scale(0.62); }
  33.33333% {
    transform: translateX(21vw) translateY(-30vh) scale(0.43); }
  38.88889% {
    transform: translateX(-6vw) translateY(8vh) scale(0.78); }
  44.44444% {
    transform: translateX(41vw) translateY(30vh) scale(0.77); }
  50% {
    transform: translateX(8vw) translateY(0vh) scale(0.61); }
  55.55556% {
    transform: translateX(32vw) translateY(-14vh) scale(0.95); }
  61.11111% {
    transform: translateX(11vw) translateY(19vh) scale(0.45); }
  66.66667% {
    transform: translateX(37vw) translateY(-19vh) scale(0.9); }
  72.22222% {
    transform: translateX(-39vw) translateY(20vh) scale(0.5); }
  77.77778% {
    transform: translateX(49vw) translateY(21vh) scale(0.86); }
  83.33333% {
    transform: translateX(-13vw) translateY(-32vh) scale(0.79); }
  88.88889% {
    transform: translateX(39vw) translateY(42vh) scale(0.52); }
  94.44444% {
    transform: translateX(37vw) translateY(-29vh) scale(0.45); }
  100% {
    transform: translateX(4vw) translateY(22vh) scale(0.7); } }

.firefly:nth-child(38) {
  animation-name: move38; }
  .firefly:nth-child(38)::before {
    animation-duration: 18s; }
  .firefly:nth-child(38)::after {
    animation-duration: 18s, 8890ms;
    animation-delay: 0ms, 4138ms; }

@keyframes move38 {
  0% {
    transform: translateX(-11vw) translateY(17vh) scale(0.77); }
  5.26316% {
    transform: translateX(30vw) translateY(45vh) scale(0.28); }
  10.52632% {
    transform: translateX(-25vw) translateY(18vh) scale(0.75); }
  15.78947% {
    transform: translateX(16vw) translateY(46vh) scale(0.84); }
  21.05263% {
    transform: translateX(15vw) translateY(-38vh) scale(0.83); }
  26.31579% {
    transform: translateX(-1vw) translateY(47vh) scale(0.89); }
  31.57895% {
    transform: translateX(-28vw) translateY(18vh) scale(0.69); }
  36.84211% {
    transform: translateX(-29vw) translateY(32vh) scale(0.87); }
  42.10526% {
    transform: translateX(12vw) translateY(12vh) scale(0.6); }
  47.36842% {
    transform: translateX(2vw) translateY(-24vh) scale(0.44); }
  52.63158% {
    transform: translateX(-43vw) translateY(34vh) scale(0.68); }
  57.89474% {
    transform: translateX(26vw) translateY(33vh) scale(0.72); }
  63.15789% {
    transform: translateX(27vw) translateY(6vh) scale(0.85); }
  68.42105% {
    transform: translateX(-49vw) translateY(21vh) scale(0.35); }
  73.68421% {
    transform: translateX(5vw) translateY(24vh) scale(0.48); }
  78.94737% {
    transform: translateX(3vw) translateY(25vh) scale(0.46); }
  84.21053% {
    transform: translateX(1vw) translateY(-35vh) scale(0.39); }
  89.47368% {
    transform: translateX(31vw) translateY(29vh) scale(0.41); }
  94.73684% {
    transform: translateX(-32vw) translateY(-12vh) scale(0.82); }
  100% {
    transform: translateX(42vw) translateY(11vh) scale(0.47); } }

.firefly:nth-child(39) {
  animation-name: move39; }
  .firefly:nth-child(39)::before {
    animation-duration: 17s; }
  .firefly:nth-child(39)::after {
    animation-duration: 17s, 7667ms;
    animation-delay: 0ms, 2594ms; }

@keyframes move39 {
  0% {
    transform: translateX(-34vw) translateY(2vh) scale(0.73); }
  4% {
    transform: translateX(-12vw) translateY(24vh) scale(0.74); }
  8% {
    transform: translateX(-38vw) translateY(-5vh) scale(0.79); }
  12% {
    transform: translateX(-12vw) translateY(8vh) scale(0.51); }
  16% {
    transform: translateX(25vw) translateY(44vh) scale(0.6); }
  20% {
    transform: translateX(34vw) translateY(19vh) scale(0.27); }
  24% {
    transform: translateX(11vw) translateY(-27vh) scale(0.45); }
  28% {
    transform: translateX(32vw) translateY(-12vh) scale(0.64); }
  32% {
    transform: translateX(-46vw) translateY(-8vh) scale(0.43); }
  36% {
    transform: translateX(-21vw) translateY(-15vh) scale(0.74); }
  40% {
    transform: translateX(16vw) translateY(-24vh) scale(0.34); }
  44% {
    transform: translateX(4vw) translateY(20vh) scale(0.84); }
  48% {
    transform: translateX(7vw) translateY(2vh) scale(0.58); }
  52% {
    transform: translateX(-11vw) translateY(-19vh) scale(0.86); }
  56% {
    transform: translateX(-31vw) translateY(48vh) scale(0.5); }
  60% {
    transform: translateX(15vw) translateY(-48vh) scale(0.49); }
  64% {
    transform: translateX(46vw) translateY(-36vh) scale(0.9); }
  68% {
    transform: translateX(39vw) translateY(19vh) scale(0.63); }
  72% {
    transform: translateX(-46vw) translateY(39vh) scale(0.36); }
  76% {
    transform: translateX(-20vw) translateY(21vh) scale(0.82); }
  80% {
    transform: translateX(11vw) translateY(-47vh) scale(1); }
  84% {
    transform: translateX(-48vw) translateY(-3vh) scale(0.7); }
  88% {
    transform: translateX(-4vw) translateY(-6vh) scale(0.82); }
  92% {
    transform: translateX(40vw) translateY(13vh) scale(0.4); }
  96% {
    transform: translateX(-20vw) translateY(-33vh) scale(0.45); }
  100% {
    transform: translateX(-44vw) translateY(-1vh) scale(0.59); } }

.firefly:nth-child(40) {
  animation-name: move40; }
  .firefly:nth-child(40)::before {
    animation-duration: 16s; }
  .firefly:nth-child(40)::after {
    animation-duration: 16s, 8061ms;
    animation-delay: 0ms, 1504ms; }

@keyframes move40 {
  0% {
    transform: translateX(-49vw) translateY(-42vh) scale(0.49); }
  5.26316% {
    transform: translateX(-29vw) translateY(37vh) scale(0.83); }
  10.52632% {
    transform: translateX(41vw) translateY(-34vh) scale(0.91); }
  15.78947% {
    transform: translateX(3vw) translateY(-33vh) scale(0.27); }
  21.05263% {
    transform: translateX(-39vw) translateY(-35vh) scale(0.6); }
  26.31579% {
    transform: translateX(-47vw) translateY(7vh) scale(0.33); }
  31.57895% {
    transform: translateX(34vw) translateY(-10vh) scale(0.3); }
  36.84211% {
    transform: translateX(-47vw) translateY(-43vh) scale(0.82); }
  42.10526% {
    transform: translateX(6vw) translateY(37vh) scale(0.85); }
  47.36842% {
    transform: translateX(31vw) translateY(-22vh) scale(0.53); }
  52.63158% {
    transform: translateX(-23vw) translateY(-17vh) scale(1); }
  57.89474% {
    transform: translateX(8vw) translateY(34vh) scale(0.53); }
  63.15789% {
    transform: translateX(-10vw) translateY(29vh) scale(0.88); }
  68.42105% {
    transform: translateX(-22vw) translateY(24vh) scale(0.97); }
  73.68421% {
    transform: translateX(-44vw) translateY(31vh) scale(0.71); }
  78.94737% {
    transform: translateX(8vw) translateY(-27vh) scale(0.79); }
  84.21053% {
    transform: translateX(-32vw) translateY(30vh) scale(0.46); }
  89.47368% {
    transform: translateX(41vw) translateY(-3vh) scale(0.44); }
  94.73684% {
    transform: translateX(-9vw) translateY(15vh) scale(0.77); }
  100% {
    transform: translateX(-46vw) translateY(-44vh) scale(1); } }

.firefly:nth-child(41) {
  animation-name: move41; }
  .firefly:nth-child(41)::before {
    animation-duration: 16s; }
  .firefly:nth-child(41)::after {
    animation-duration: 16s, 10502ms;
    animation-delay: 0ms, 2039ms; }

@keyframes move41 {
  0% {
    transform: translateX(-42vw) translateY(32vh) scale(0.68); }
  4.34783% {
    transform: translateX(41vw) translateY(10vh) scale(0.42); }
  8.69565% {
    transform: translateX(-9vw) translateY(49vh) scale(0.36); }
  13.04348% {
    transform: translateX(13vw) translateY(-47vh) scale(0.7); }
  17.3913% {
    transform: translateX(18vw) translateY(19vh) scale(0.35); }
  21.73913% {
    transform: translateX(39vw) translateY(-26vh) scale(0.79); }
  26.08696% {
    transform: translateX(-43vw) translateY(44vh) scale(0.88); }
  30.43478% {
    transform: translateX(-26vw) translateY(-3vh) scale(0.85); }
  34.78261% {
    transform: translateX(46vw) translateY(-28vh) scale(0.56); }
  39.13043% {
    transform: translateX(20vw) translateY(30vh) scale(0.91); }
  43.47826% {
    transform: translateX(-27vw) translateY(-49vh) scale(0.73); }
  47.82609% {
    transform: translateX(39vw) translateY(-28vh) scale(0.28); }
  52.17391% {
    transform: translateX(39vw) translateY(15vh) scale(0.8); }
  56.52174% {
    transform: translateX(-15vw) translateY(19vh) scale(1); }
  60.86957% {
    transform: translateX(17vw) translateY(11vh) scale(0.95); }
  65.21739% {
    transform: translateX(-9vw) translateY(-4vh) scale(0.64); }
  69.56522% {
    transform: translateX(50vw) translateY(39vh) scale(0.85); }
  73.91304% {
    transform: translateX(14vw) translateY(7vh) scale(0.51); }
  78.26087% {
    transform: translateX(9vw) translateY(-42vh) scale(0.94); }
  82.6087% {
    transform: translateX(28vw) translateY(46vh) scale(0.42); }
  86.95652% {
    transform: translateX(13vw) translateY(-30vh) scale(0.57); }
  91.30435% {
    transform: translateX(-48vw) translateY(-15vh) scale(0.62); }
  95.65217% {
    transform: translateX(-2vw) translateY(-43vh) scale(0.47); }
  100% {
    transform: translateX(17vw) translateY(-34vh) scale(0.37); } }

.firefly:nth-child(42) {
  animation-name: move42; }
  .firefly:nth-child(42)::before {
    animation-duration: 15s; }
  .firefly:nth-child(42)::after {
    animation-duration: 15s, 7523ms;
    animation-delay: 0ms, 6634ms; }

@keyframes move42 {
  0% {
    transform: translateX(7vw) translateY(-48vh) scale(0.54); }
  4.16667% {
    transform: translateX(36vw) translateY(49vh) scale(0.67); }
  8.33333% {
    transform: translateX(3vw) translateY(-17vh) scale(0.71); }
  12.5% {
    transform: translateX(-28vw) translateY(-37vh) scale(0.75); }
  16.66667% {
    transform: translateX(19vw) translateY(-20vh) scale(0.92); }
  20.83333% {
    transform: translateX(49vw) translateY(-45vh) scale(0.28); }
  25% {
    transform: translateX(-13vw) translateY(26vh) scale(0.66); }
  29.16667% {
    transform: translateX(-33vw) translateY(6vh) scale(0.43); }
  33.33333% {
    transform: translateX(-42vw) translateY(49vh) scale(0.42); }
  37.5% {
    transform: translateX(-37vw) translateY(26vh) scale(0.84); }
  41.66667% {
    transform: translateX(3vw) translateY(3vh) scale(0.26); }
  45.83333% {
    transform: translateX(-24vw) translateY(20vh) scale(0.87); }
  50% {
    transform: translateX(-9vw) translateY(26vh) scale(0.49); }
  54.16667% {
    transform: translateX(-45vw) translateY(-30vh) scale(0.53); }
  58.33333% {
    transform: translateX(-44vw) translateY(34vh) scale(0.96); }
  62.5% {
    transform: translateX(-46vw) translateY(-4vh) scale(0.7); }
  66.66667% {
    transform: translateX(13vw) translateY(-21vh) scale(0.29); }
  70.83333% {
    transform: translateX(24vw) translateY(-29vh) scale(0.85); }
  75% {
    transform: translateX(-22vw) translateY(-42vh) scale(0.37); }
  79.16667% {
    transform: translateX(-43vw) translateY(-13vh) scale(0.53); }
  83.33333% {
    transform: translateX(11vw) translateY(28vh) scale(0.97); }
  87.5% {
    transform: translateX(36vw) translateY(-27vh) scale(0.52); }
  91.66667% {
    transform: translateX(-19vw) translateY(-18vh) scale(0.48); }
  95.83333% {
    transform: translateX(8vw) translateY(-5vh) scale(0.56); }
  100% {
    transform: translateX(42vw) translateY(-13vh) scale(0.73); } }

.firefly:nth-child(43) {
  animation-name: move43; }
  .firefly:nth-child(43)::before {
    animation-duration: 16s; }
  .firefly:nth-child(43)::after {
    animation-duration: 16s, 9113ms;
    animation-delay: 0ms, 2891ms; }

@keyframes move43 {
  0% {
    transform: translateX(29vw) translateY(-46vh) scale(0.96); }
  4% {
    transform: translateX(5vw) translateY(-46vh) scale(0.91); }
  8% {
    transform: translateX(-49vw) translateY(-37vh) scale(0.59); }
  12% {
    transform: translateX(-48vw) translateY(-28vh) scale(0.8); }
  16% {
    transform: translateX(-18vw) translateY(-31vh) scale(0.98); }
  20% {
    transform: translateX(-30vw) translateY(-10vh) scale(0.38); }
  24% {
    transform: translateX(45vw) translateY(-43vh) scale(0.86); }
  28% {
    transform: translateX(8vw) translateY(-3vh) scale(0.99); }
  32% {
    transform: translateX(27vw) translateY(41vh) scale(0.54); }
  36% {
    transform: translateX(-42vw) translateY(11vh) scale(0.6); }
  40% {
    transform: translateX(-16vw) translateY(-13vh) scale(0.28); }
  44% {
    transform: translateX(-41vw) translateY(-48vh) scale(0.92); }
  48% {
    transform: translateX(23vw) translateY(20vh) scale(0.93); }
  52% {
    transform: translateX(-45vw) translateY(-25vh) scale(1); }
  56% {
    transform: translateX(-46vw) translateY(11vh) scale(0.77); }
  60% {
    transform: translateX(-41vw) translateY(-23vh) scale(0.87); }
  64% {
    transform: translateX(14vw) translateY(-37vh) scale(0.65); }
  68% {
    transform: translateX(9vw) translateY(20vh) scale(0.82); }
  72% {
    transform: translateX(31vw) translateY(1vh) scale(0.87); }
  76% {
    transform: translateX(-11vw) translateY(-31vh) scale(0.64); }
  80% {
    transform: translateX(-45vw) translateY(5vh) scale(0.92); }
  84% {
    transform: translateX(9vw) translateY(5vh) scale(0.76); }
  88% {
    transform: translateX(14vw) translateY(-14vh) scale(0.77); }
  92% {
    transform: translateX(26vw) translateY(4vh) scale(0.74); }
  96% {
    transform: translateX(36vw) translateY(-8vh) scale(0.67); }
  100% {
    transform: translateX(-18vw) translateY(12vh) scale(0.66); } }

.firefly:nth-child(44) {
  animation-name: move44; }
  .firefly:nth-child(44)::before {
    animation-duration: 16s; }
  .firefly:nth-child(44)::after {
    animation-duration: 16s, 7687ms;
    animation-delay: 0ms, 4261ms; }

@keyframes move44 {
  0% {
    transform: translateX(0vw) translateY(42vh) scale(0.76); }
  3.7037% {
    transform: translateX(48vw) translateY(-29vh) scale(0.95); }
  7.40741% {
    transform: translateX(34vw) translateY(-25vh) scale(0.4); }
  11.11111% {
    transform: translateX(44vw) translateY(-32vh) scale(0.58); }
  14.81481% {
    transform: translateX(-16vw) translateY(35vh) scale(0.58); }
  18.51852% {
    transform: translateX(7vw) translateY(26vh) scale(0.86); }
  22.22222% {
    transform: translateX(-16vw) translateY(-7vh) scale(0.94); }
  25.92593% {
    transform: translateX(-48vw) translateY(-48vh) scale(0.44); }
  29.62963% {
    transform: translateX(-17vw) translateY(31vh) scale(0.6); }
  33.33333% {
    transform: translateX(19vw) translateY(-39vh) scale(0.99); }
  37.03704% {
    transform: translateX(-30vw) translateY(-5vh) scale(0.45); }
  40.74074% {
    transform: translateX(26vw) translateY(40vh) scale(0.78); }
  44.44444% {
    transform: translateX(-3vw) translateY(9vh) scale(0.59); }
  48.14815% {
    transform: translateX(-3vw) translateY(17vh) scale(0.39); }
  51.85185% {
    transform: translateX(-44vw) translateY(-31vh) scale(0.92); }
  55.55556% {
    transform: translateX(44vw) translateY(-17vh) scale(0.26); }
  59.25926% {
    transform: translateX(16vw) translateY(35vh) scale(0.39); }
  62.96296% {
    transform: translateX(-17vw) translateY(-24vh) scale(0.63); }
  66.66667% {
    transform: translateX(-31vw) translateY(28vh) scale(0.79); }
  70.37037% {
    transform: translateX(27vw) translateY(-13vh) scale(0.42); }
  74.07407% {
    transform: translateX(23vw) translateY(-42vh) scale(0.92); }
  77.77778% {
    transform: translateX(13vw) translateY(-22vh) scale(0.95); }
  81.48148% {
    transform: translateX(-31vw) translateY(-17vh) scale(0.57); }
  85.18519% {
    transform: translateX(-19vw) translateY(-15vh) scale(0.77); }
  88.88889% {
    transform: translateX(-28vw) translateY(50vh) scale(0.8); }
  92.59259% {
    transform: translateX(8vw) translateY(6vh) scale(0.72); }
  96.2963% {
    transform: translateX(39vw) translateY(-15vh) scale(1); }
  100% {
    transform: translateX(2vw) translateY(23vh) scale(0.66); } }

.firefly:nth-child(45) {
  animation-name: move45; }
  .firefly:nth-child(45)::before {
    animation-duration: 13s; }
  .firefly:nth-child(45)::after {
    animation-duration: 13s, 6093ms;
    animation-delay: 0ms, 801ms; }

@keyframes move45 {
  0% {
    transform: translateX(-2vw) translateY(-16vh) scale(0.67); }
  3.84615% {
    transform: translateX(34vw) translateY(-16vh) scale(0.76); }
  7.69231% {
    transform: translateX(-31vw) translateY(9vh) scale(0.32); }
  11.53846% {
    transform: translateX(25vw) translateY(18vh) scale(0.93); }
  15.38462% {
    transform: translateX(-14vw) translateY(-19vh) scale(0.44); }
  19.23077% {
    transform: translateX(40vw) translateY(-12vh) scale(0.67); }
  23.07692% {
    transform: translateX(18vw) translateY(20vh) scale(0.77); }
  26.92308% {
    transform: translateX(27vw) translateY(41vh) scale(0.89); }
  30.76923% {
    transform: translateX(44vw) translateY(-5vh) scale(0.79); }
  34.61538% {
    transform: translateX(26vw) translateY(36vh) scale(0.46); }
  38.46154% {
    transform: translateX(-13vw) translateY(-2vh) scale(0.53); }
  42.30769% {
    transform: translateX(-49vw) translateY(-12vh) scale(0.58); }
  46.15385% {
    transform: translateX(-38vw) translateY(26vh) scale(0.53); }
  50% {
    transform: translateX(-27vw) translateY(16vh) scale(0.31); }
  53.84615% {
    transform: translateX(-27vw) translateY(20vh) scale(0.41); }
  57.69231% {
    transform: translateX(36vw) translateY(11vh) scale(0.52); }
  61.53846% {
    transform: translateX(8vw) translateY(9vh) scale(0.43); }
  65.38462% {
    transform: translateX(25vw) translateY(6vh) scale(0.87); }
  69.23077% {
    transform: translateX(-49vw) translateY(-4vh) scale(0.66); }
  73.07692% {
    transform: translateX(13vw) translateY(28vh) scale(0.69); }
  76.92308% {
    transform: translateX(-49vw) translateY(-31vh) scale(0.97); }
  80.76923% {
    transform: translateX(-46vw) translateY(46vh) scale(0.47); }
  84.61538% {
    transform: translateX(-2vw) translateY(-43vh) scale(0.68); }
  88.46154% {
    transform: translateX(48vw) translateY(-41vh) scale(0.81); }
  92.30769% {
    transform: translateX(-5vw) translateY(-8vh) scale(0.78); }
  96.15385% {
    transform: translateX(-48vw) translateY(26vh) scale(0.48); }
  100% {
    transform: translateX(-29vw) translateY(-18vh) scale(0.74); } }

.firefly:nth-child(46) {
  animation-name: move46; }
  .firefly:nth-child(46)::before {
    animation-duration: 16s; }
  .firefly:nth-child(46)::after {
    animation-duration: 16s, 5943ms;
    animation-delay: 0ms, 6946ms; }

@keyframes move46 {
  0% {
    transform: translateX(-40vw) translateY(-45vh) scale(0.32); }
  5% {
    transform: translateX(-6vw) translateY(-17vh) scale(0.29); }
  10% {
    transform: translateX(5vw) translateY(-39vh) scale(0.79); }
  15% {
    transform: translateX(-47vw) translateY(-1vh) scale(0.35); }
  20% {
    transform: translateX(-10vw) translateY(-7vh) scale(0.67); }
  25% {
    transform: translateX(-37vw) translateY(-31vh) scale(0.47); }
  30% {
    transform: translateX(22vw) translateY(33vh) scale(0.56); }
  35% {
    transform: translateX(13vw) translateY(-18vh) scale(0.55); }
  40% {
    transform: translateX(-12vw) translateY(2vh) scale(0.88); }
  45% {
    transform: translateX(42vw) translateY(47vh) scale(0.87); }
  50% {
    transform: translateX(-21vw) translateY(-4vh) scale(0.57); }
  55% {
    transform: translateX(38vw) translateY(22vh) scale(0.44); }
  60% {
    transform: translateX(47vw) translateY(-49vh) scale(0.61); }
  65% {
    transform: translateX(-30vw) translateY(-49vh) scale(0.86); }
  70% {
    transform: translateX(46vw) translateY(15vh) scale(0.41); }
  75% {
    transform: translateX(10vw) translateY(-17vh) scale(0.26); }
  80% {
    transform: translateX(11vw) translateY(24vh) scale(0.54); }
  85% {
    transform: translateX(-22vw) translateY(14vh) scale(0.4); }
  90% {
    transform: translateX(-24vw) translateY(-13vh) scale(0.87); }
  95% {
    transform: translateX(50vw) translateY(13vh) scale(0.85); }
  100% {
    transform: translateX(-5vw) translateY(30vh) scale(0.29); } }

.firefly:nth-child(47) {
  animation-name: move47; }
  .firefly:nth-child(47)::before {
    animation-duration: 14s; }
  .firefly:nth-child(47)::after {
    animation-duration: 14s, 6838ms;
    animation-delay: 0ms, 2604ms; }

@keyframes move47 {
  0% {
    transform: translateX(-25vw) translateY(43vh) scale(0.75); }
  3.84615% {
    transform: translateX(-35vw) translateY(22vh) scale(0.42); }
  7.69231% {
    transform: translateX(45vw) translateY(17vh) scale(0.58); }
  11.53846% {
    transform: translateX(46vw) translateY(-20vh) scale(0.81); }
  15.38462% {
    transform: translateX(11vw) translateY(-44vh) scale(0.64); }
  19.23077% {
    transform: translateX(17vw) translateY(-10vh) scale(0.45); }
  23.07692% {
    transform: translateX(11vw) translateY(11vh) scale(0.38); }
  26.92308% {
    transform: translateX(-44vw) translateY(8vh) scale(0.98); }
  30.76923% {
    transform: translateX(7vw) translateY(17vh) scale(0.92); }
  34.61538% {
    transform: translateX(-28vw) translateY(-39vh) scale(0.8); }
  38.46154% {
    transform: translateX(-4vw) translateY(33vh) scale(0.31); }
  42.30769% {
    transform: translateX(-45vw) translateY(-31vh) scale(0.44); }
  46.15385% {
    transform: translateX(-30vw) translateY(-44vh) scale(0.77); }
  50% {
    transform: translateX(9vw) translateY(-6vh) scale(0.69); }
  53.84615% {
    transform: translateX(26vw) translateY(15vh) scale(0.79); }
  57.69231% {
    transform: translateX(-27vw) translateY(15vh) scale(0.8); }
  61.53846% {
    transform: translateX(48vw) translateY(-26vh) scale(0.84); }
  65.38462% {
    transform: translateX(-28vw) translateY(32vh) scale(0.82); }
  69.23077% {
    transform: translateX(45vw) translateY(16vh) scale(0.66); }
  73.07692% {
    transform: translateX(-16vw) translateY(-33vh) scale(0.76); }
  76.92308% {
    transform: translateX(4vw) translateY(4vh) scale(0.84); }
  80.76923% {
    transform: translateX(-28vw) translateY(-19vh) scale(0.82); }
  84.61538% {
    transform: translateX(15vw) translateY(-9vh) scale(0.85); }
  88.46154% {
    transform: translateX(-18vw) translateY(-18vh) scale(0.58); }
  92.30769% {
    transform: translateX(8vw) translateY(-9vh) scale(0.54); }
  96.15385% {
    transform: translateX(-49vw) translateY(9vh) scale(0.64); }
  100% {
    transform: translateX(1vw) translateY(3vh) scale(0.55); } }

.firefly:nth-child(48) {
  animation-name: move48; }
  .firefly:nth-child(48)::before {
    animation-duration: 16s; }
  .firefly:nth-child(48)::after {
    animation-duration: 16s, 5962ms;
    animation-delay: 0ms, 4465ms; }

@keyframes move48 {
  0% {
    transform: translateX(29vw) translateY(-7vh) scale(0.47); }
  3.7037% {
    transform: translateX(46vw) translateY(16vh) scale(0.28); }
  7.40741% {
    transform: translateX(-39vw) translateY(7vh) scale(0.66); }
  11.11111% {
    transform: translateX(-48vw) translateY(-39vh) scale(0.93); }
  14.81481% {
    transform: translateX(-19vw) translateY(-6vh) scale(0.99); }
  18.51852% {
    transform: translateX(-3vw) translateY(23vh) scale(0.88); }
  22.22222% {
    transform: translateX(-41vw) translateY(44vh) scale(0.83); }
  25.92593% {
    transform: translateX(40vw) translateY(-32vh) scale(0.46); }
  29.62963% {
    transform: translateX(-23vw) translateY(-17vh) scale(0.46); }
  33.33333% {
    transform: translateX(36vw) translateY(-8vh) scale(0.73); }
  37.03704% {
    transform: translateX(-7vw) translateY(34vh) scale(0.74); }
  40.74074% {
    transform: translateX(-40vw) translateY(-11vh) scale(0.99); }
  44.44444% {
    transform: translateX(39vw) translateY(3vh) scale(0.54); }
  48.14815% {
    transform: translateX(9vw) translateY(-37vh) scale(0.43); }
  51.85185% {
    transform: translateX(-21vw) translateY(29vh) scale(0.44); }
  55.55556% {
    transform: translateX(-25vw) translateY(18vh) scale(0.59); }
  59.25926% {
    transform: translateX(17vw) translateY(-18vh) scale(0.8); }
  62.96296% {
    transform: translateX(-21vw) translateY(-13vh) scale(0.45); }
  66.66667% {
    transform: translateX(-38vw) translateY(-17vh) scale(0.8); }
  70.37037% {
    transform: translateX(21vw) translateY(-6vh) scale(0.71); }
  74.07407% {
    transform: translateX(49vw) translateY(49vh) scale(0.4); }
  77.77778% {
    transform: translateX(36vw) translateY(-25vh) scale(0.84); }
  81.48148% {
    transform: translateX(-4vw) translateY(33vh) scale(0.54); }
  85.18519% {
    transform: translateX(-44vw) translateY(-9vh) scale(0.86); }
  88.88889% {
    transform: translateX(8vw) translateY(-48vh) scale(0.89); }
  92.59259% {
    transform: translateX(-43vw) translateY(19vh) scale(0.71); }
  96.2963% {
    transform: translateX(-14vw) translateY(26vh) scale(0.81); }
  100% {
    transform: translateX(44vw) translateY(-16vh) scale(0.99); } }

.firefly:nth-child(49) {
  animation-name: move49; }
  .firefly:nth-child(49)::before {
    animation-duration: 12s; }
  .firefly:nth-child(49)::after {
    animation-duration: 12s, 6076ms;
    animation-delay: 0ms, 2349ms; }

@keyframes move49 {
  0% {
    transform: translateX(43vw) translateY(-43vh) scale(0.35); }
  4% {
    transform: translateX(-2vw) translateY(44vh) scale(0.63); }
  8% {
    transform: translateX(-9vw) translateY(4vh) scale(0.78); }
  12% {
    transform: translateX(-10vw) translateY(47vh) scale(0.77); }
  16% {
    transform: translateX(27vw) translateY(-45vh) scale(0.71); }
  20% {
    transform: translateX(20vw) translateY(-42vh) scale(0.34); }
  24% {
    transform: translateX(-10vw) translateY(-43vh) scale(0.36); }
  28% {
    transform: translateX(-16vw) translateY(17vh) scale(0.27); }
  32% {
    transform: translateX(-38vw) translateY(-41vh) scale(0.48); }
  36% {
    transform: translateX(-17vw) translateY(18vh) scale(0.39); }
  40% {
    transform: translateX(36vw) translateY(48vh) scale(0.75); }
  44% {
    transform: translateX(18vw) translateY(-17vh) scale(0.6); }
  48% {
    transform: translateX(43vw) translateY(25vh) scale(0.43); }
  52% {
    transform: translateX(-34vw) translateY(11vh) scale(0.47); }
  56% {
    transform: translateX(-26vw) translateY(0vh) scale(0.79); }
  60% {
    transform: translateX(47vw) translateY(41vh) scale(0.41); }
  64% {
    transform: translateX(34vw) translateY(17vh) scale(0.72); }
  68% {
    transform: translateX(36vw) translateY(47vh) scale(0.57); }
  72% {
    transform: translateX(-30vw) translateY(-1vh) scale(0.84); }
  76% {
    transform: translateX(18vw) translateY(11vh) scale(0.88); }
  80% {
    transform: translateX(-47vw) translateY(-26vh) scale(0.98); }
  84% {
    transform: translateX(9vw) translateY(31vh) scale(0.33); }
  88% {
    transform: translateX(29vw) translateY(-5vh) scale(0.69); }
  92% {
    transform: translateX(40vw) translateY(-24vh) scale(0.34); }
  96% {
    transform: translateX(-16vw) translateY(-46vh) scale(0.32); }
  100% {
    transform: translateX(-14vw) translateY(21vh) scale(0.37); } }

.firefly:nth-child(50) {
  animation-name: move50; }
  .firefly:nth-child(50)::before {
    animation-duration: 11s; }
  .firefly:nth-child(50)::after {
    animation-duration: 11s, 10937ms;
    animation-delay: 0ms, 4182ms; }

@keyframes move50 {
  0% {
    transform: translateX(12vw) translateY(-35vh) scale(0.95); }
  3.7037% {
    transform: translateX(4vw) translateY(2vh) scale(0.99); }
  7.40741% {
    transform: translateX(-27vw) translateY(3vh) scale(0.71); }
  11.11111% {
    transform: translateX(-21vw) translateY(10vh) scale(0.38); }
  14.81481% {
    transform: translateX(30vw) translateY(-41vh) scale(0.49); }
  18.51852% {
    transform: translateX(-27vw) translateY(-36vh) scale(0.72); }
  22.22222% {
    transform: translateX(-5vw) translateY(-28vh) scale(0.58); }
  25.92593% {
    transform: translateX(2vw) translateY(-27vh) scale(0.91); }
  29.62963% {
    transform: translateX(-1vw) translateY(49vh) scale(0.4); }
  33.33333% {
    transform: translateX(43vw) translateY(-13vh) scale(0.8); }
  37.03704% {
    transform: translateX(-19vw) translateY(31vh) scale(0.38); }
  40.74074% {
    transform: translateX(-32vw) translateY(11vh) scale(0.94); }
  44.44444% {
    transform: translateX(39vw) translateY(28vh) scale(0.3); }
  48.14815% {
    transform: translateX(9vw) translateY(-13vh) scale(0.56); }
  51.85185% {
    transform: translateX(2vw) translateY(41vh) scale(0.99); }
  55.55556% {
    transform: translateX(6vw) translateY(-17vh) scale(0.68); }
  59.25926% {
    transform: translateX(-41vw) translateY(35vh) scale(0.43); }
  62.96296% {
    transform: translateX(-29vw) translateY(48vh) scale(0.33); }
  66.66667% {
    transform: translateX(0vw) translateY(10vh) scale(0.34); }
  70.37037% {
    transform: translateX(32vw) translateY(-47vh) scale(0.49); }
  74.07407% {
    transform: translateX(3vw) translateY(33vh) scale(0.9); }
  77.77778% {
    transform: translateX(26vw) translateY(34vh) scale(0.9); }
  81.48148% {
    transform: translateX(36vw) translateY(-43vh) scale(0.31); }
  85.18519% {
    transform: translateX(21vw) translateY(45vh) scale(0.94); }
  88.88889% {
    transform: translateX(-14vw) translateY(-42vh) scale(0.97); }
  92.59259% {
    transform: translateX(-47vw) translateY(-4vh) scale(0.49); }
  96.2963% {
    transform: translateX(-35vw) translateY(49vh) scale(0.34); }
  100% {
    transform: translateX(19vw) translateY(12vh) scale(0.71); } }

@keyframes drift {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

@keyframes flash {
  0%, 30%, 100% {
    opacity: 0;
    box-shadow: 0 0 0vw 0vw yellow; }
  5% {
    opacity: 1;
    box-shadow: 0 0 2vw 0.4vw yellow; } }


/* Animation */

.page-content {
    display: flex;
    flex-flow: wrap;
    align-items: center;
    justify-content: space-evenly;
    margin: 50px auto;
    max-width: 1250px;
}

.embeded-video {
   padding: 10px;
}

.my-social-icon {
    height: 40px;
    width: 40px;
}

.my-social-icon:hover {
    transform: scale(1.25);
}


/* Digital */

.sub-page .page-content h2 {
  flex-basis: 100%;
  font-size: 25px;
  margin-left: 10px;
}

.sub-page .page-content img {
    margin-bottom: 25px;
}

.modal {
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  opacity: 0;
  pointer-events: none;
  transition: 0.25s ease;
}

.modal.open {
  opacity: 1;
  pointer-events: all;
}

.full-img {
  position: absolute;
  max-height: 70%;
  max-width: 90%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0.5);
  transition: all 0.25s ease-out;
}

.full-img.open {
  transform: translate(-50%, -50%) scale(1);
}


/* About Me */

.about .page-content {
    flex-flow: nowrap;
    max-width: 1000px;
}

.about .page-content img {
    height: 280px;
    border-radius: 10%;
    margin-bottom: 0;
}

.about-description {
    margin: 30px;
    font-size: 25px;
}


/* 
#cf {
    position: relative;
    height: 280px;
    width: 450px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
}

#cf img {
    position: absolute;
    width: 90%;
    max-width: 350px;
    -webkit-transition: opacity 1s ease-in-out;
    -moz-transition: opacity 1s ease-in-out;
    -o-transition: opacity 1s ease-in-out;
    transition: opacity 1s ease-in-out;
    border-radius: 10%;
}

#cf img.top:hover {
    opacity: 0;
} */

.about-description {}

@media only screen and (max-width: 767px) {
    .page-content {
        margin: 30px auto;
    }
    .nav-images {
        width: 100%;
        /* top: 45%; */
    }
    .nav-images>.img-link {
        width: 40%;
        padding: 0;
    }
    h1.page-name {
        font-size: 13vw;
    }
    .sub-page .page-content img {
        margin-bottom: 25px;
        object-fit: cover;
        width: 250px;
        height: 250px;
    }
    .my-social-icon {
        height: 35px !important;
        width: 35px !important;
    }
    .about .page-content {
        flex-flow: wrap;
        align-items: flex-start;
        max-width: 1000px;
    }
    .about .page-content img {
        height: 200px;
        width: 200px;
        border-radius: 10%;
    }
    .about-description {
        margin: 0px 25px;
        font-size: 20px;
    }
    .reel{
        width: 88%;
        height: 200px;
    }
}